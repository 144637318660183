import { useEffect, useRef, useCallback } from 'react';

const useBroadcastChannel = (channelName, onMessage) => {
  const channelRef = useRef(null);

  useEffect(() => {
    channelRef.current = new BroadcastChannel(channelName);

    const handleMessage = (event) => {
      if (onMessage) {
        onMessage(event.data);
      }
    };

    channelRef.current.addEventListener('message', handleMessage);

}, [channelName, onMessage]);

  const postMessage = useCallback((message) => {
    if (channelRef.current) {
      channelRef.current.postMessage(message);
    }
  }, []);

  return postMessage;
};

export default useBroadcastChannel;
