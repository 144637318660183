import React from "react"
import { Modal, Text, View } from "react-native";
import BoxButton from "../../Components/BoxButton";
import { TitleCard } from "../../Screens/Dashboard/TitleCard";
import { SideBar, Wrapper, Content } from "../../Components/Layout";
import Loader from "../../Components/Loader/index.web";
import AppFonts from "../../Constants/Fonts";
import getQuery from "../getQuery";
import ModalWrapper from "../../Components/Modals/ModalWrapper";
import DateTimePicker from 'react-datetime-picker';
import { Icon } from "react-native-elements";
import AppColor from "../../Constants/Color";
import End_Points from "../../Constants/Api";
import CustomButton from "../../Components/Button/index.web";


const ResellerDashboard = ({ route, navigation }) => {
    const { data, isLoading } = getQuery(
        {
            action: "get_reseller_stats",
            resellerId: route.params.reseller
        }
    )

    const [addonsModal, setAddonsModal] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [from, setFrom] = React.useState(new Date());
    const [to, setTo] = React.useState(new Date());

    const [accountsModal, setAccountsModal] = React.useState(false);

    if (isLoading) return <Loader backgroundColor={'transparent'} />
    if (!data) return null;

    const runAddonReport = () => {
        setAddonsModal(false);
        window.open(End_Points.Base_url + "dart/core?action=run_reseller_account_report&to=" + date.getTime() + "&format=csv" + "&reseller=" + route.params.reseller );
    }

    const runAccountsReport = () => {
        setAccountsModal(false)
        window.open(End_Points.Base_url + "dart/core?action=run_account_report&from=" + from.getTime() + "&to=" + to.getTime() + "&format=csv" + "&reseller=" + route.params.reseller );

    }


    return (
        <Wrapper>
            <SideBar style={{flex:2}}>
                <View style={{ flex: 1, paddingVertical: 25, paddingHorizontal: 15 }}>
                    <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontWeight: 500, fontSize: 18, marginBottom: 20 }}>Account Data</Text>

                    <TitleCard type='row'
                        title='Groups'
                        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                        subtitle={data.affiliates}
                    />
                    <TitleCard type='row'
                        title='Accounts'
                        style={{ borderTopLeftRadius: 0, borderTop: 'none', borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                        subtitle={data.tenants}
                    />
                    <TitleCard type='row'
                        title='Accounts'
                        style={{ borderTopLeftRadius: 0, borderTop: 'none', borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                        subtitle={data.loginCount}
                    />
                    <TitleCard type='row'
                        title='Voice Add-Ons'
                        style={{ borderTopLeftRadius: 0, borderTop: 'none', borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                        subtitle={data.voiceCount}
                    />
                    <TitleCard type='row'
                        title='Recording Add-Ons'
                        style={{ borderTopLeftRadius: 0, borderTop: 'none', borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                        subtitle={data.recordCount}
                    />
                    <TitleCard type='row'
                        title='Transcribe Add-Ons'
                        style={{ borderTopLeftRadius: 0, borderTop: 'none', borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                        subtitle={data.transcribeCount}
                    />
                    <TitleCard type='row'
                        title='Archive Add-Ons'
                        style={{ borderTopLeftRadius: 0, borderTop: 'none', borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                        subtitle={data.archiveCount}
                    />
                    <TitleCard type='row'
                        title='Additional Logins'
                        style={{ borderTopLeftRadius: 0, borderTop: 'none', borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                        subtitle={data.addnlLogins}
                    />
                    <TitleCard type='row'
                        title='Group Admin Total'
                        style={{ borderTopLeftRadius: 0, borderTop: 'none', borderTopRightRadius: 0 }}
                        subtitle={data.groupAdminCount}
                    />
                    <View style={{ flexDirection: 'row', gap: 20, marginTop: 10 }}>
                        <TitleCard type='block' subtitle={`${data.groupTenantLimit}`} title='License Limit' />
                        <TitleCard type='block' subtitle={`${data.groupTenantLimit - data.tenants}`} title='Unused Licenses' />

                    </View>


                </View>
            </SideBar>
            <Content>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 15, padding: 15 }}>

                    <BoxButton onPress={() => setAddonsModal(true)} title={'Export Add-On Report'} iconName={'export'} iconType={'antdesign'} />
                    <BoxButton onPress={() => setAccountsModal(true)} title={'Export Accounts Report'} iconName={'export'} iconType={'antdesign'} />

                </View>
            </Content>
            {addonsModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={addonsModal} transparent={true}>
                    <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 18, textAlign: 'center' }}>Account Add-On Report</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, textAlign: 'left', marginTop: 10 }}>Choose the created by date for this account report.</Text>
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                Created By Date
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={setDate}
                                    value={date}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10, marginTop: 20 }}>
                            <CustomButton onPress={() => setAddonsModal(false)} outline width={'30%'} title={'Cancel'} />
                            <CustomButton onPress={runAddonReport} width={'30%'} title={'Run'} />
                        </View>
                    </ModalWrapper>
                </Modal>
            }
            {accountsModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={accountsModal} transparent={true}>
                    <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 18, textAlign: 'center' }}>Account Info Report</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, textAlign: 'left', marginTop: 10 }}>Choose the date range to use for stats for this account report.</Text>
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                From
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={setFrom}
                                    value={from}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>                        
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                To
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={setTo}
                                    value={to}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10, marginTop: 20 }}>
                            <CustomButton onPress={() => setAccountsModal(false)} outline width={'30%'} title={'Cancel'} />
                            <CustomButton onPress={runAccountsReport} width={'30%'} title={'Run'} />
                        </View>
                    </ModalWrapper>
                </Modal>
            }
        </Wrapper>
    )
}

export default ResellerDashboard;