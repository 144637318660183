import React from 'react';
import {View} from 'react-native';

const Content = props => {
    return(
        <View style={{flex:2,height:'100%',backgroundColor:'white'}}>
            {props.children}
        </View>
    )
    
}

Content.Row = props => {
    return(
        <View style={{flexDirection:'row',alignItems:'center',...props.style}}>
            {props.children}
        </View>
    )
}

export default Content;