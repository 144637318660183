import { Icon } from 'react-native-elements';
import React from 'react';
import { useState } from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Linking,
  FlatList,
} from 'react-native';
import Alert from 'Alert';

import { SafeAreaView } from 'react-native-safe-area-context';
import AppColor from '../../Constants/Color';
import getData from '../../AsyncUtils/GetData';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import { useToast } from 'native-base';
import { useFocusEffect } from '@react-navigation/native';
import { Content, SideBar, Wrapper } from '../../Components/Layout';
import { providers } from '../IntegrationProvider';
import moment from 'moment';
import { formatNumber, formatPhoneNumber } from '../../Middleware/helpers';
import DialpadInput from './DialpadInput';
import Search from '../../Components/Search';
import AppFonts from '../../Constants/Fonts';
import useLogEvent from '../../Middleware/useLogEvent';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';

const DialPad = ({ navigation }) => {
  const Toast = useToast();
  const [pad, setPad] = useState([]);
  const [calls, setCalls] = useState([]);
  const [search, setSearch] = useState('');
  const logEvent = useLogEvent();
  const {isWebCompact} = useResponsiveLayout();

  const call = () => {
    if (pad.length == 10) {
      handleCall('+1' + pad).then((x) => {
        setTimeout(() => {
          Linking.openURL(`tel:${pad}`);
        }, 3000);
      });
    } else {
      Alert.alert(
        'Error Message',
        'The format of your number is incorrect. Please input again and retry.'
      );
    }
  };
  useFocusEffect(
    React.useCallback(() => {
      setPad([]);
    }, [])
  );
  const handleCall = async (phoneNumber) => {
    try {
      const body = {
        action: 'initiate_call',
        toNumber: phoneNumber,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        logEvent('call_placed');
        Toast.show({
          duration: 2000,
          title: 'Call initiated, Use your phone to complete the call',
          status: 'success',
        });
      } else {
        Alert.alert('Eror Message', result.data.failures.join(','));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      getRecentCalls();
    }, [])
  )

    const getRecentCalls = async () => {
      try {
        const body = {
          action: 'get_recent_member_message',
          days: 90,
          offset: 0,
          filter: 'call',
          limit: 100,
        };
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        if (result.data.success) {
          setCalls(result.data.members)
        }
      } catch (err) {
        console.log(err);
      }
    }


  const renderItem = ({ item }) => {
    let image = require('../../Assets/Images/splashlogo.png');
    let imageAlt = 'MyRepChat logo.';
    let provider = Object.values(providers).find((x) => x.name == item.source);
    if (provider) {
      image = provider.smallImageUrlPath;
      imageAlt = `${provider.name} Logo.`;
    }

    const time = moment(item.when).format('h:mm A')
    const formattedNumber = formatPhoneNumber(item.mobile);

    const date = moment(item.when).format('MMM DD YYYY')

    const handleSelectItem = () => {
      setPad(item.mobile);
    }

    return (
      <TouchableOpacity onPress={handleSelectItem} style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 20 }}>
        <Icon name={item.direction === 'outbound' ? 'phone-outgoing' : 'phone-incoming'} type='feather' size={22} />
        <img src={image} style={{ width: 40, height: 40, borderRadius:100,marginLeft:10 }} />
        <View style={{ marginLeft: 10 }}>
          <Text style={{ marginBottom: 5,color: AppColor.PrimaryColor,fontFamily: AppFonts.Roboto_Regular,fontSize: 16}}>
          {item.fname} {item.lname}
          </Text>
          <Text style={{fontFamily:AppFonts.Roboto_Regular,color:AppColor.Dark}}>
            {formattedNumber}
          </Text>
        </View>
        <View style={{marginLeft:'auto',justifyContent:'flex-end',alignItems:'flex-end'}}>
        <Text syle={{color: AppColor.Dark,fontFamily: AppFonts.Roboto_Regular,fontSize: 14,}}>{time}</Text>
        <Text syle={{ color: AppColor.Dark,fontFamily: AppFonts.Roboto_Regular,fontSize: 14,}}>{date}</Text>
        </View>

      </TouchableOpacity>
    )
  }


  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      {/* <View style={{ width: widthPercentageToDP(100), alignItems: 'center' }}><Search /></View> */}
      <Wrapper>
        {!isWebCompact && <SideBar>
          <Search placeholder={'Filter recent calls'} onChangeText={val => setSearch(val)} />
          <ScrollView style={{ flex: 1 }}>

            <FlatList
              ItemSeparatorComponent={() => {
                return (
                  <View
                    style={{
                      height: 1,
                      backgroundColor: '#DEDCD9',
                      width: '95%',
                      alignSelf: 'center',
                    }}></View>
                );
              }}
            
              data={calls.filter(e => `${String(e.fname).toLowerCase() + ' ' + String(e.lname).toLowerCase()}`.includes(search.toLowerCase()))}
              renderItem={e => renderItem(e)}
              style={{ flex: 1, width: '90%', alignSelf: 'center' }}
              ListEmptyComponent={
                <Text style={{ alignSelf: 'center', marginTop: 20 }}>
                  No call history
                </Text>

              }

            />
          </ScrollView>

        </SideBar>}

        <Content>
          <View style={{ marginTop: 50, flexDirection: 'row', justifyContent: 'space-around', marginBottom: 20 }}>
            <TouchableOpacity
              onPress={() => navigation.navigate('DialpadSearch')}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Icon
                color={'black'}
                name={'search1'}
                type={'antdesign'}
                style={{ marginRight: 10 }}
              />
              <Text style={{ color: 'black' }}>Search</Text>
            </TouchableOpacity>
          </View>
          <DialpadInput
            onCall={call}
            onChange={setPad}
            value={pad}
          />
        </Content>

      </Wrapper>

    </SafeAreaView>
  );
};
export default DialPad;
