import React from 'react';
import { Text, View } from 'react-native';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button/index.web';
import RequestMaker from '../../Middleware/ApiCaller';
import { useToast } from 'native-base';
import DateTimePicker from 'react-datetime-picker';
import CustomInput from '../../Components/CustomInput/index.web';


const SystemAdminTools = ({ tenant }) => {
    const Tenant = tenant.data.tenant;
    const Toast = useToast();
    const [monthlyBillingDate, setMonthlyBillingDate] = React.useState(new Date(Tenant.billingDate));
    const [customerID, setCustomerID] = React.useState(Tenant.customer_id);

    React.useEffect(()=>{
        setCustomerID(Tenant.customer_id);
        setMonthlyBillingDate(new Date(Tenant.billingDate));
    },[tenant.data.tenant])

    const handleDelete = async (action) => {
        try{
        const body = {
            tenant: Tenant.id,
            action: action
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            if(result.data.deleteCount){
                Toast.show({
                    title: "Success",
                    status: "success",
                    description: `${result.data.deleteCount} phone numbers deleted successfully.`
                })
            } else {
                Toast.show({
                    title: "Success",
                    status: "success",
                    description: `No phone numbers found to delete.`
                })
            }
        } else if(result.data.errorMessage){
            Toast.show({
                title: "Error",
                status: "error",
                description: result.data.errorMessage
            })
        }
        } catch(e){
            Toast.show({
                title: "Error",
                status: "error",
                description: "An error occurred while deleting phone numbers."
            })
        }
    }

    const handleChangeTrialDate = async (date) => {
        try{
        const body = {
            tenant: Tenant.id,
            startDate: new Date(date).getTime(),
            action: "update_trial_start_date"
        }

        const result = await RequestMaker(body);

        if(result.data.success){

            Toast.show({
                title: "Success",
                status: "success",
                description: "Trial start date updated successfully."
            })

            tenant.refetch();

        } else if(result.data.errorMessage){
            Toast.show({
                title: "Error",
                status: "error",
                description: result.data.errorMessage
            })
        }
        } catch(e){
            Toast.show({
                title: "Error",
                status: "error",
                description: "An error occurred while updating the trial start date."
            })
        }
    }

    const handleSaveMonthlyBillingDate = async () => {
        try{
        const body = {
                name: Tenant.name,
                address1: Tenant.address1,
                address2: Tenant.address2,
                city: Tenant.city,
                state: Tenant.state,
                zip: Tenant.zip,
                contact: Tenant.contact,
                phone: Tenant.phone,
                isoCountry: Tenant.isoCountry,
                email: Tenant.email,
                archive: Tenant.archive,
                voice: Tenant.voice,
                record: Tenant.record,
                transcribe: Tenant.transcribe,
                status: Tenant.status,
                timezone: Tenant.timezone,
                uniqueId: Tenant.uniqueId,
                cdr: Tenant.cdr,
                toast: Tenant.toast,
                features: Tenant.features,
                billingDate: Number(monthlyBillingDate),
                customer_id: String(customerID),
                merchant: Tenant.merchant,
                tenant_id: Tenant.id,
                action: "update_tenant"
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            Toast.show({
                title: "Success",
                status: "success",
                description: "Updated successfully."
            })
            tenant.refetch();
        } else if(result.data.errorMessage){
            Toast.show({
                title: "Error",
                status: "error",
                description: result.data.errorMessage
            }) 
        }
        } catch(e){
            Toast.show({
                title: "Error",
                status: "error",
                description: e.message
            })
        }
    }
    
    return(
        <View style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                This is information and functions that are only available to system administrators.
            </Text>

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Creation Date
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {Tenant.created}
            </Text>

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                UUID
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {Tenant.uuid}
            </Text>

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                AWS BUCKET
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {Tenant.bucket}
            </Text>

            <View style={{flexDirection:'row',alignItems:'center',marginVertical:20,gap:20}}>
                <CustomButton onPress={() => handleDelete('delete_blank_phone_numbers')} width={'30%'} title={'Delete Blank Phone Numbers'} />
                <CustomButton onPress={() => handleDelete('delete_no_phone_members')} width={'30%'} title={'Delete No Phone Contacts'} />
                <CustomButton onPress={() => handleDelete('delete_members')} width={'30%'} title={'Delete All Contacts'} />
            </View>

            <Text style={{fontFamily:AppFonts.Roboto_Bold}}>
                Trial Start Date
            </Text>

            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginVertical:10}}>
                Set this to the date that you want the accounts trial to begin on.
            </Text>
            
            <DateTimePicker
                
              calendarType="US"
              onChange={handleChangeTrialDate}
              value={new Date(Tenant.created)}
              disableClock={true}
              format={'MM/dd/y'}
            />

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Billing
            </Text>

            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginVertical:10}}>
                Manage the monthly billing date, and brain tree customer id for this account.
            </Text>

            <DateTimePicker    
              calendarType="US"
              onChange={setMonthlyBillingDate}
              value={monthlyBillingDate}
              disableClock={true}
              format={'MM/dd/y'}
            />

            <CustomInput
                value={customerID}
                onChangeText={setCustomerID}
                placeholder={'Customer ID'}
                style={{marginTop:10}}
            />

            <CustomButton
            title={'Save'}
            width={'20%'}
            style={{alignSelf:'flex-end',marginTop:10}}
            onPress={handleSaveMonthlyBillingDate}
            />

        </View>
    )

}

export default SystemAdminTools;