import React, { useState, useCallback, useEffect } from 'react';
import {
  Day,
  GiftedChat,
  LoadEarlier,
  Composer,
} from 'react-native-gifted-chat';
import {
  StyleSheet,
  Image,
  Text,
  TouchableOpacity,
  View,
  Keyboard,
  ActivityIndicator,
  ScrollView,
  Linking,
} from 'react-native';
import { useToast } from 'native-base';
import Alert from 'Alert';
import { Icon } from 'react-native-elements';
// import RNFS from 'react-native-fs';
import FileViewer from 'react-native-file-viewer';
import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';
import RequestMaker from '../../Middleware/ApiCaller';
import End_Points from '../../Constants/Api';
import getData from '../../AsyncUtils/GetData';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Entypo from 'react-native-vector-icons/Entypo';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Modal from 'modal-react-native-web';
import { useFocusEffect } from '@react-navigation/native';
import Loader1 from '../../Components/Loader';
// image preview
import ImageViewer from 'react-native-image-zoom-viewer';
// audio
import AudioPlayer from '../../Components/AudioPlayer';
//
import AllCollections from './AlltachmentsModalContainer';
import { SafeAreaView } from 'react-native-safe-area-context';
const moment = require('moment');
import { useSelector, useDispatch } from 'react-redux';
import Clipboard from '@react-native-clipboard/clipboard';
import { providers } from '../IntegrationProvider';
import { Tooltip } from 'native-base';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { loadRecentMessagesThunk } from '../../Redux/recent_message';
import EmojisModal from '../../Components/Modals/EmojisModal';
import { getMessages } from '../../Api/chat';
import firebase from '../../../firebase_config';

const Chats = ({ navigation, route }) => {
  // const [messages, setMessages] = useState([]);
  const tenant = useSelector((state) => state.User?.profile?.tenant);
  const group = useSelector((state) => state.User)?.profile?.group;

  const Toast = useToast();
  const [typedmessage, setTypedmessage] = useState('');
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const { template_message, memberData } = route.params;
  const [paddingBottom, setPaddingBottom] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [lockdown, setLockDown] = React.useState(false);
  const [consent, setConsent] = React.useState(true);
  const [consentMsg, setConsentMsg] = React.useState('');
  const { newMessage } = useSelector((state) => state.receivedNewMessage);
  const [member, setMember] = React.useState({
    fname: '....',
    lname: '....',
    mname: '',
    email_address: '',
    address: '',
    state: '',
    city: '',
    zip: '',
    phoneNumbers: [
      {
        messaging: true,
        longCode: '...........',
      },
    ],
    distributionLists: [],
    stats: {},
    source: 'default',
    sourceId: '',
    spam: false,
  });
  const [messages, setMessages] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [multiVisible, setMultiVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [phoneNumbers, setPhoneNumbers] = React.useState([]);
  const [phoneModalVisible, setPhoneModalVisible] = React.useState(false);
  const [selectedMultiData, setSelectedMultiData] = React.useState({
    type: '',
    url: '',
  });
  const [dl, setdl] = React.useState(null);
  const [emojiModalVisible, setEmojiModalVisible] = React.useState(false);
  const [loadMoreMessages, setLoadMoreMessages] = React.useState(false);
  const [messagePage, setMessagePage] = React.useState(1);

  const dispatch = useDispatch();
  // after selecting templatemessage from  select template screen check and set it
  useFocusEffect(
    React.useCallback(() => {
      if (template_message) {
        setTypedmessage(template_message);
      } else {
        setTypedmessage('');
      }
    }, [template_message])
  );

  React.useEffect(() => {
    if(memberData?.id){
      if (Number(newMessage?.data?.contactId) === memberData.id) {
        loadMessage();
      } 
    }
    
    // if(!consent){
    //   get_consent();
    // }

  }, [newMessage]);

  React.useEffect(()=>{
    let timer;
    const focus = navigation.addListener('focus', () => {
      if(Notification.permission !== 'granted' || !firebase.messaging.isSupported()){
        timer = setInterval(()=>{
          loadMessage()
        },60000)
      } 
    })

    const blur = navigation.addListener('blur', () => {
      if(timer){
        clearInterval(timer);
      }
    })

    return focus, blur;

  },[navigation])




  const loadRouteParams = () => {
    if (route.params?.memberData) {
      let memberData = { ...route.params.memberData };
      if (memberData.fname) {
        memberData['phoneNumbers'] = [
          {
            messaging: true,
            longCode: memberData.mobile,
            countryCode: memberData.countryCode,
          },
        ];
        setMember(memberData);
      }
      loadMemberDetails();
      get_consent();
    }
    if (route.params?.dl) {
      setConsent(true);
      setMessages([]);
      setdl(route.params.dl);
    } else { setdl(null)}
  };

  React.useEffect(() => {
    loadRouteParams();
  }, [route]);

  const loadMessage = async () => {
    if (memberData?.id) {
      try {
        const body = {
          action: 'get_messages',
          member: memberData.id,
          offset: 0,
          limit: 100,
        };
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        if (result.data.success) {
          // if(result?.data?.communications?.length === 50){
          //   setLoadMoreMessages(true);
          // }

          let new_message_array = []; 

          for(let i=0;i < result.data.communications.length; i++){
            let msg = result.data.communications[i];
            let prevMsg = result.data.communications[i - 1];

            if(!(prevMsg?.messages?.findIndex(e => e?.sms_sid === msg?.messages[0]?.sms_sid) > -1) || msg?.messages[0]?.sms_sid === "" || msg?.messages[0]?.sms_sid === "none" ){

              if(msg.type == 'sms' && msg.messages.length != 0){
                new_message_array.push(msg)
              }else if(msg.type != 'sms'){ //added this to show mms messages and 'vcard' messsages. 
                new_message_array.push(msg)
              }

            }
          }

          let messages = new_message_array
            .map((x) => ({
              mms: x.mms,
              direction: x.direction,
              type: x.type,
              date: x.date,
              id: x.id,
              call_status: x.call_status,
              sms: x.messages,
              records: x.recordings,
              transcripts: x.transcripts,
            }))
            .map((x) => {
              return {
                _id: x.id,
                createdAt: x.date,
                type: x.type,
                direction: x.direction,
                user: {
                  _id: x.direction == 'outbound' ? 1 : 2,
                },
                sms_status: x.sms_status,
                call_status: x.call_status,
                mms: x.mms,
                sms: x.sms,
                records: x.records,
                transcripts: x.transcripts,
              };
            });

            
            if(!consent){
              if(messages.findIndex(e => {
                  return e?.direction === 'inbound'
              }) > -1 ) {
                if(group?.consentInboundAccepts){
                  setTypedmessage(''); 
                  setConsent(true);
                } else {
                  const result = get_consent();
                  if(result) {
                    setTypedmessage('');
                    setConsent(true);
                  }
                }
              }
          }

          setMessages(messages);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleLoadMoreMessages = async () => {
    await getMessages({member: memberData.id, offset: messagePage * 50, limit: 50})
    .then(async result => {
      if (result?.data?.success) {

        if(result?.data?.communications?.length !== 50){
          setLoadMoreMessages(false);
        } else {
          setMessagePage(messagePage + 1);
        }

        let new_message_array = []; 

        for(let i=0;i < result.data.communications.length; i++){
          let msg = result.data.communications[i];
          let prevMsg = result.data.communications[i - 1];

          if(!(prevMsg?.messages?.findIndex(e => e?.sms_sid === msg?.messages[0]?.sms_sid) > -1) || msg?.messages[0]?.sms_sid === ""){

            if(msg.type == 'sms' && msg.messages.length != 0){
              new_message_array.push(msg)
            }else if(msg.type != 'sms'){ //added this to show mms messages and 'vcard' messsages. 
              new_message_array.push(msg)
            }

          }
        }
        

        let messages = new_message_array
          .map((x) => ({
            mms: x.mms,
            direction: x.direction,
            type: x.type,
            date: x.date,
            id: x.id,
            call_status: x.call_status,
            sms: x.messages,
            records: x.recordings,
            transcripts: x.transcripts,
            senders: x.senders,
          }))
          .map((x) => {
            return {
              _id: x.id,
              createdAt: x.date,
              type: x.type,
              direction: x.direction,
              user: {
                _id: x.direction == 'outbound' ? 1 : 2,
              },
              sms_status: x.sms_status,
              call_status: x.call_status,
              mms: x.mms,
              sms: x.sms,
              records: x.records,
              transcripts: x.transcripts,
              senderName: x.senders.length == 0 ? '' : x.senders[0].name,
            };
          });


          setMessages(prevState => {
            return [ ...messages, ...prevState]
          })


      }
    })
    .catch(err => {
      console.log(err);
    })
  }


  React.useEffect(() => {
    //loadMessage();
  }, [memberData]);

  useFocusEffect(
    React.useCallback(() => {
      loadMessage();
      loadMemberNumbers();

    }, [memberData])
  );

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      (event) => {
        setPaddingBottom(event.endCoordinates.height - 30);
        setKeyboardVisible(true); // or some other action
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setPaddingBottom(0);
        setKeyboardVisible(false); // or some other action
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  const sendMessage = async (text, dl) => {
    
    try {
      const body = {
        action: 'send_text_message',
        type: 'sms',
        text: text,
      };
      if (dl == null) {
        body['members'] = [route.params.memberData.id];
      } else {
        body['distributionLists'] = [{ text: dl.text }];
      }
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.status == 400) {
        if (tenant.status == 3) {
          Toast.show({
            duration: 2000,
            title:
              'You are not able to send messages while your account is in review state.',
            status: 'info',
          });
        } else {
          Toast.show({
            duration: 2000,
            title: 'You are not able to send messages at this time.',
            status: 'info',
          });
        }
      }
      if (result.data.success) {
        if (dl != null) {
          Toast.show({ duration: 2000, title: 'Message sent successfully.' });
        }
      } else if (
        result?.data?.failures?.length
      ) {
        Toast.show({ duration: 2000, title: result.data.failures.join(',') });
      } else if (result.data.errorMessage) {
        Toast.show({
          duration: 4000,
          title: result.data.errorMessage,
          status: 'error',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: ' We were unable to send your message',
        });
      }

    } catch (err) {
      console.log(err, 'eeeee');
    } finally {
      loadMessage();

      navigation.navigate('Messages', {memberData: route?.params?.memberData ? route.params.memberData : null});
    }
  };

  const onSend = useCallback(
    (messages = []) => {
      if (memberData?.id) {
        setMessages((previousMessages) =>
          GiftedChat.append(previousMessages, messages)
        );
      }
      sendMessage(messages.text, dl);
    },
    [dl, memberData]
  );

  const renderDay = (props) => {
    return <Day {...props} textStyle={CommonStyle.dayText} />;
  };

  const isConsentMessageSent = () => {
    const index = messages.findIndex(e => {
      return e?.sms[0]?.sms === consentMsg
    })

    return index > -1;
    
  }

  const renderComposer = (props) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          maxHeight: 80,
          minHeight: 50,
          paddingHorizontal: 10,
        }}>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Composer
            {...props}
            textInputProps={{
              placeholder: 'Type here.',
              value: typedmessage,
              onChangeText: (message) => setTypedmessage(message),
              
            }}
            multiline={true}
            textInputStyle={{
              textAlignVertical: 'center',
              marginTop: 0,
              paddingTop: 0,
              marginHorizontal: 0,
              lineHeight: '1.2em',
              paddingHorizontal: 10,
              fontSize: 18,
              fontFamily: AppFonts.Roboto_Regular,
              backgroundColor: '#F2F4FB',
            }}></Composer>
        </View>

        {
          (dl?.id && group?.consentEnabled) && 
          <Tooltip
          label="Send Consent"
          openDelay={500}
          placement="top"
          hasArrow={true}>

          <TouchableOpacity
            style={{ marginHorizontal: 10 }}
            onPress={() => handleConsentAction()}>
              <Entypo name="thumbs-up" size={25} color={AppColor.PrimaryColor} />
          </TouchableOpacity>

        </Tooltip>
        }
        <TouchableOpacity
              disabled={!consent}
              style={{ marginHorizontal: 10 }}
              onPress={()=>setEmojiModalVisible(!emojiModalVisible)}>
              <Icon
                name="smile"
                type='feather'
                size={25}
                color={AppColor.PrimaryColor}
              />
        </TouchableOpacity>

        <TouchableOpacity
          style={{ marginHorizontal: 10 }}
          onPress={() => setVisible(!visible)}>
          <Entypo name="attachment" size={25} color={AppColor.PrimaryColor} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            if (consent) {
              const mes = {
                _id: Date.now() + Math.random(0, 2000),
                text: typedmessage,
                sms_status: 'sending',
                createdAt: new Date(),
                user: {
                  _id: 1,
                },
                type: '',
                transactionAmount: '402',
                transactionstatus: 'Received',
                records: [],
                transcripts: [],
              };
              typedmessage.trim() != '' ? onSend(mes) : setTypedmessage('');
              setTypedmessage('');
            } else {
              handleConsentAction();
            }
          }}
          activeOpacity={1}
          style={{
            width: 40,
            borderRadius: 25,
            height: 40,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: AppColor.PrimaryColor,
          }}>
          <Icon color={'white'} type={'font-awesome'} name={'send'} />
        </TouchableOpacity>
      </View>
      // </InputToolbar>
    );
  };
  const renderLoadEarlier = (props) => {
    return <LoadEarlier {...props} textStyle={CommonStyle.loadEarlierText} />;
  };

  const handleCall = async (phoneNumber) => {
    try {
      const body = {
        action: 'initiate_call',
        toNumber: phoneNumber,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Call initiated, Use your phone to complete the call',
          status: 'success',
        });
        setTimeout(() => {
          Linking.openURL(`tel:${phoneNumber}`);
        }, 2000);
      } else if (result.data?.errorMessage) {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.failures.join(','),
          status: 'error',
        });
      }
      setTimeout(() => {
        loadMessage();
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  // consecent
  const showConsentDialog = (consent) => {
    let msg = '';
    if (consent.state == 'pending') {
      let sb =
        'This contact was previously sent the required consent message at ';
      sb += moment(consent.lastchanged ?? new Date()).format(
        'MM/DD/YYYY hh:mm:ss'
      );
      sb +=
        ' but has not been accepted. Click send button to re-send the required consent message.';
      msg = sb;
    } else {
      msg =
        'This contact has not accepted the required consent message. This message cannot be changed. Click send button to send the required consent message.';
    }
    Alert.alert('Consent Required', msg);
  };

  const get_consent = async () => {
    if (memberData.id) {
      try {
        const body = {
          action: 'get_consent',
          member: memberData.id,
        };
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);

        if (result.data?.success) {
          if (result.data.state === 'accepted') {
            setConsent(true);
            if (template_message) {
              setTypedmessage(template_message);
            }
            return true

          } else if(result.data.state === 'pending' ){
            if(group?.consentResponseRequired){
              showConsentDialog(result.data.consent);
              setConsent(false);
              if(result.data?.consentMsg) setTypedmessage(result.data.consentMsg)
            }
          } else {
            setTypedmessage(result.data.consentMsg);
            setConsentMsg(result.data.consentMsg)
            showConsentDialog(result.data.consent);
            setConsent(false);
          }
          return false;
        } else {
          Alert.alert('Error', result?.data?.errorMessage);
        }

      } catch (err) {
        console.log(err);
      }


    }
  };

  const handleConsentSend = async (id, dl = false) => {
    try {
      let body = {};
      if (route.params?.dl?.id) {
        body = {
          action: 'send_consent',
          distributionList: id,
        };
      } else {
        body = {
          action: 'send_consent',
          id: id,
        };
      }

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log(result);
      if(result?.data?.success){
        if(!group?.consentResponseRequired){
          setConsent(true);
          setTypedmessage('');
        }
        dispatch(loadRecentMessagesThunk())
        loadMessage();  
      } else {
        alert(result?.data?.errorMessage || 'Something went wrong, please try again later')
      }
    } catch (err) {
      console.log(err);
    } 
  };

  const handleConsentAction = () => {
    if (dl == null) {
      console.log('Clicked send message button for consent to contact: ');
      handleConsentSend(memberData.id, false);
      if(group?.consentResponseRequired){
        Alert.alert(
          'Consent Sent',
          'Consent request sent. You will not be able to text this contact until the keyword accept is sent back.'
        );
      } else {
        Alert.alert(
          'Consent Sent',
          'The consent message(s) have been sent to contact(s)'
        );
      }
      navigation.goBack(); //back out after sending since there's nothing left to do.
    } else if (dl != null) {
      console.log('Clicked send message button for consent to DL: ');
      handleConsentSend(dl.id, true);
      if(group?.consentResponseRequired){
      Alert.alert(
        'Consent Sent',
        'Consent request sent. You will not be able to text this contact until the keyword accept is sent back.'
      );
      }
      navigation.goBack(); //back out after sending since there's nothing left to do.
    } else {
      Alert.alert('Error', 'No contact selected to send consent');
    }
  };

  const loadMemberDetails = async (id) => {
    if (memberData?.id) {
      try {
        const body = {
          action: 'get_member',
          id: memberData.id,
        };
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        if (result.status == 200 && result.data.id) {
          setMember(result.data);
          if (result.data.stopped) {
            Alert.alert(
              'Opted Out',
              'This contact has opted out of receiving sms messages from you. You will not be able to send them any message through SMS until they opt back in (send the word START to your ' +
                'MyRepChat ' +
                ' number).'
            );
          }
        } else {
        }
      } catch (err) {
        console.log(err);
      } finally {
      }
    }
  };

  const loadMemberNumbers = async () => {
    if (memberData?.id) {
      try {
        const body = {
          action: 'get_member_phone_numbers',
          member: memberData.id,
        };
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        if (result.data.success) {
          setPhoneNumbers(result.data.phonenumbers);
        } else {
          Alert.alert('Error Message', result.data.failures.join(','));
        }
        setTimeout(() => {
          loadMessage();
        }, 2000);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const HeaderLogo = () => {
    let image = require('../../Assets/Images/splashlogo.png');
    let imageAlt = 'MyRepChat logo.';
    let provider = Object.values(providers).find((x) => x.name == member.source);
    if (provider) {
      image = provider.smallImageUrlPath;
      imageAlt = `${provider.name} logo.`
    }

    return (
      <img
        style={{ width: 40, height: 40, borderRadius: 35, marginLeft: 20 }}
        src={image}
        alt={imageAlt}
      />
    );
  };

  return (
    <>
      <SafeAreaView
        style={{ flex: 1, backgroundColor: 'white' }}
        edges={['bottom', 'top']}>
        <View style={{ flex: 1 }}>
          <View
            style={{
              width: '100%',
              backgroundColor: AppColor.PrimaryColor,
              paddingVertical: 5,
              paddingHorizontal: 20,
            }}>
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
              }}>
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <img
                  alt="Back button."
                  src={require('../../Assets/Images/arbw.png')}
                  style={{ width: 23, height: 12, margin: 5, marginLeft: 10 }}
                />
              </TouchableOpacity>
              <View>
                <HeaderLogo />
              </View>
              <TouchableOpacity
                onPress={() => {
                  if (dl == null) {
                    navigation.navigate('ContactEdit', { item: memberData });
                  } else {
                  }
                }}
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  paddingHorizontal: 15,
                }}>
                {dl == null ? (
                  <>
                    <Text
                      style={{
                        fontFamily: AppFonts.Roboto_Regular,
                        fontSize: 20,
                        color: 'white',
                      }}
                      numberOfLines={1}>
                      {member.fname} {member.mname} {member.lname}
                    </Text>
                    <Text
                      style={{
                        fontFamily: AppFonts.Roboto_Regular,
                        fontSize: 14,
                        color: 'white',
                      }}>
                      {
                        member.phoneNumbers.find((x) => x.messaging)
                          ?.countryCode
                      }
                      {member.phoneNumbers.find((x) => x.messaging)?.longCode}
                    </Text>
                  </>
                ) : (
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Regular,
                      fontSize: 20,
                      color: 'white',
                    }}>
                    {dl.text}
                  </Text>
                )}
              </TouchableOpacity>
              { tenant?.voice &&
              <TouchableOpacity
                onPress={() => {
                  // handleCall()
                  if (dl == null) {
                    setPhoneModalVisible(true);
                  }
                }}>
                <Icon color={'white'} type={'material'} name={'phone'} />
              </TouchableOpacity>
              }
            </View>
          </View>
          {/* <Loader /> */}
          <GiftedChat
            messagesContainerStyle={{maxWidth:'100%'}}
            inverted={false}
            // loadEarlier={loadMoreMessages}
            // onLoadEarlier={handleLoadMoreMessages}
            messages={messages}
            onSend={(messages) => onSend(messages)}
            user={{
              _id: 1,
            }}
            renderDay={renderDay}
            listViewProps={{
              initialNumToRender: 10,
            }}
            renderChatEmpty={() => (
              <View
                style={{
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}>
                <Text
                  style={{
                    color: AppColor.Dark,
                    transform: [{ rotateX: '180deg' }],
                  }}>
                  {dl ? 'Type message to send to DL' : 'Empty messages'}
                </Text>
              </View>
            )}
            textInputProps={{
              autoCapitalize: 'none',
              autoCorrect: false,
            }}
            renderLoading={renderLoadEarlier}
            // renderInputToolbar={renderComposer}
            renderComposer={renderComposer}
            renderTime={renderTime}
            renderLoadEarlier={renderLoadEarlier}
            renderBubble={(props) => (
              <RenderBubble
                {...props}
                setMultiVisible={setMultiVisible}
                setSelectedMultiData={setSelectedMultiData}
                setLoading={setLoading}
              />
            )}
            showUserAvatar={false}
            isKeyboardInternallyHandled={true}
            bottomOffset={5}
          />
        </View>
      </SafeAreaView>

      {/* attachment types*/}
      <Modal
        visible={visible}
        // animationIn={"slideInUp"}
        // animationOut={"slideOutUp"}

        transparent={true}
        style={{ justifyContent: 'flex-end' }}>
        <AllCollections
          typedmessage={typedmessage}
          dl={dl}
          navigation={navigation}
          setVisible={setVisible}
          memberData={memberData}
          loadMessage={loadMessage}
          setLoading={setLoading}
        />
      </Modal>
      {/* multimedia preview */}
      <Modal
        visible={multiVisible}
        onBackdropPress={() => {
          setMultiVisible(false);
          setSelectedMultiData({
            type: '',
            url: '',
          });
        }}
        transparent={true}
        backdropOpacity={0.7}
        style={{ justifyContent: 'center' }}>
        <MultiPreviews data={selectedMultiData} />
      </Modal>
      <Modal
        visible={phoneModalVisible}
        onBackdropPress={() => {
          setPhoneModalVisible(false);
        }}
        transparent={true}
        backdropOpacity={0.7}
        useNativeDriver={true}
        style={{ justifyContent: 'center' }}>
        <RenderPhoneModal
          handleCall={handleCall}
          phoneNumbers={phoneNumbers}
          setPhoneModalVisible={setPhoneModalVisible}
        />
      </Modal>
      
      {
      emojiModalVisible &&
      <EmojisModal
      visible={emojiModalVisible}
      setVisible={setEmojiModalVisible}
      onPressEmoji={e => {
        setTypedmessage(typedmessage + e)
        setEmojiModalVisible(false)
      }}
      />
      }
      {loading && <Loader1 />}
    </>
  );
};

function MultiPreviews({ data }) {
  const images = [
    {
      url: data.url,
      props: {},
    },
  ];
  switch (data.type) {
    case 'image':
      return (
        <View style={{ height: 500 }}>
          <ImageViewer useNativeDriver={true} imageUrls={images} />
        </View>
      );
    case 'video':
      return (
        <View>
          <Text>video</Text>
        </View>
      );
    case 'audio':
      return (
        <View
          style={{
            height: 500,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <AudioPlayer url={data.url} />
        </View>
      );
    case '': {
      return null;
    }
    default:
      return (
        <View>
          <Text>default</Text>
        </View>
      );
  }
}

function Loader(a) {
  return (
    <ActivityIndicator
      animating={a}
      color={AppColor.PrimaryColor}
      size={70}
      style={{ position: 'absolute', top: '50%', left: '45%', zIndex: 1 }}
    />
  );
}
const openDocument = (url, type, setLoading) => {
  // setLoading(true)
  // const localFile = `${RNFS.DocumentDirectoryPath}/temp.${type}`;
  // const options = {
  //     fromUrl: url,
  //     toFile: localFile
  // };
  // RNFS.downloadFile(options).promise
  //     .then(() => {
  //         setLoading(false)
  //         FileViewer.open(localFile).catch(err => {
  //         })
  //     })
  //     .then(() => {
  //         setLoading(false)
  //     })
  //     .catch(error => {
  //         setLoading(false)
  //     })
};

const renderTime = (props) => {
  return (
    <View style={{ width: 140, marginHorizontal: 2 }}>
      <Text
        style={{
          textAlign: props.position == 'right' ? 'right' : 'right',
          fontSize: 12,
          fontFamily: AppFonts.Roboto_Regular,
          color: props.position == 'right' ? AppColor.SecondaryColor : 'black',
        }}>
        {props.currentMessage.sms_status}{' '}
        {moment(props.currentMessage.createdAt).format('h:mm a')}
      </Text>
    </View>
  );
};

const RenderBubble = (props) => {
  const [modal, setModal] = React.useState(false);
  const [text, setText] = React.useState('');
  const tenant = useSelector((state) => state.User?.profile?.tenant);


  const handleShowTranscript = async id => {
    const body = {
      action: 'get_transcript',
      communication: id,
    };
    
    let device = await getData('@device_id');
    const headers = { 'x-device': device };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
      headers: headers,
      redirect: 'follow',
    };
    let result = await RequestMaker(Config);
    if(result.data.success){
      setModal(true)
      setText(result?.data?.jobs[0]?.results?.transcripts[0]?.transcript)
  
    }
  }
  
  const URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;

  const renderText = msg =>
    msg
      .split("\n")
      .map(part =>
        part.split(" ")
        .map(e => URL_REGEX.test(e) ? <a target={'_blank'} href={e}>{e}</a> : `${e} `)   
      )

      


  let statusCall = props.currentMessage.call_status ?? '';
  if (
    props.currentMessage.direction == 'inbound' &&
    props.currentMessage.call_status == 'ringing'
  ) {
    statusCall = 'inbound';
  }
  return (
    <>
      <View
        style={{
          marginLeft: props.currentMessage.user._id == 2 ? -40 : 10,
          marginBottom: 5,
          maxWidth:'95%'
        }}>
        {props.currentMessage.type == 'sms' &&
          props.currentMessage.sms.map((x) => (
            <View
              
              activeOpacity={0.5}
              style={
                props.currentMessage.user._id == 2
                  ? CommonStyle.left
                  : CommonStyle.right
              }>
              <Text
                style={
                  props.currentMessage.user._id == 1
                    ? CommonStyle.bubbleRightText
                    : CommonStyle.bubbleLeftText
                }>
                {x.sms
                .split("\n")
                .map(part =>
                  <>{
                  part.split(" ")
                  .map(e => URL_REGEX.test(e) ? <a target={'_blank'} href={e}>{e}</a> : `${e} `)   
                  }
                  {
                    '\n'
                  }
                  </>
                  
                )
                }
              </Text>
              {
              (x?.sms !== '') &&
              <Text
                style={{
                  textAlign:
                    props.currentMessage.user._id == 1 ? 'right' : 'right',
                  fontSize: 12,
                  fontFamily: AppFonts.Roboto_Regular,
                  color:
                    props.currentMessage.user._id == 1
                      ? 'white'
                      : AppColor.PrimaryColor,
                }}>
                {x.sms_status} {moment(x.time).format('h:mm a')}
              </Text>
              }
            </View>
          ))}
        {/* type calls */}
        {props.currentMessage.type == 'call' && (
          <View
            style={
              props.currentMessage.user._id == 2
                ? CommonStyle.left
                : CommonStyle.right
            }>
            {props.currentMessage.direction == 'outbound' && (
              <MaterialIcons
                name="phone"
                size={40}
                color={
                  props.currentMessage.user._id == 1
                    ? 'white'
                    : AppColor.PrimaryColor
                }
              />
            )}
            {props.currentMessage.direction == 'inbound' && (
              <MaterialIcons
                name="phone"
                size={40}
                color={
                  props.currentMessage.user._id == 1
                    ? 'white'
                    : AppColor.PrimaryColor
                }
              />
            )}
            <Text
              style={{
                textAlign:
                  props.currentMessage.user._id == 1 ? 'right' : 'right',
                fontSize: 12,
                fontFamily: AppFonts.Roboto_Regular,
                color:
                  props.currentMessage.user._id == 1
                    ? 'white'
                    : AppColor.PrimaryColor,
              }}>
              {' '}
              {statusCall}{' '}
              {moment(props.currentMessage.createdAt).format('h:mm a')}
            </Text>
          </View>
        )}
        {/* type mms */}
        {(props.currentMessage.type == 'mms' || props?.currentMessage?.sms?.findIndex(e => e !== '') > -1) &&
          props.currentMessage.mms.map((x) => (
            <>
            <TouchableOpacity
              onPress={() => {
                props.setSelectedMultiData({
                  type: x.contentType.split('/')[0],
                  url: x.mms_ur,
                });
                if (
                  x.contentType.includes('video') ||
                  x.contentType.includes('audio') ||
                  x.contentType.includes('image')
                ) {
                  // props.setMultiVisible(true)

                  openDocument(
                    x.mms_url,
                    x.contentType.split('/')[1],
                    props.setLoading
                  );
                } else {
                  openDocument(
                    x.mms_url,
                    x.contentType.split('/')[1],
                    props.setLoading
                  );
                }
              }}
              activeOpacity={0.6}
              style={
                props.currentMessage.user._id == 2
                  ? CommonStyle.left
                  : CommonStyle.right
              }>
              {x.contentType.includes('image') && (
                <Image
                  style={{ height: 100, width: 100, alignSelf: 'center' }}
                  resizeMode="cover"
                  source={{ uri: x.mms_url}}
                />
              )}
              {x.contentType.includes('audio') && (
                <Entypo name="controller-play" color={'white'} size={40} />
              )}
              {x.contentType.includes('video') && (
                <Entypo
                  name="controller-play"
                  color={
                    props.currentMessage.user._id == 2
                      ? AppColor.PrimaryColor
                      : 'white'
                  }
                  size={40}
                />
              )}
              {!x.contentType.includes('video') &&
                !x.contentType.includes('audio') &&
                !x.contentType.includes('image') && (
                  <View>
                    <AntDesign
                      name="file1"
                      color={
                        props.currentMessage.user._id == 2
                          ? AppColor.PrimaryColor
                          : 'white'
                      }
                      size={30}
                    />
                    <Text
                      style={
                        props.currentMessage.user._id == 1
                          ? CommonStyle.bubbleRightText
                          : CommonStyle.bubbleLeftText
                      }>
                      {x.contentType.split('/')[1]} file
                    </Text>
                  </View>
                )}
              <Text
                style={{
                  textAlign:
                    props.currentMessage.user._id == 1 ? 'right' : 'right',
                  fontSize: 12,
                  fontFamily: AppFonts.Roboto_Regular,
                  color:
                    props.currentMessage.user._id == 1
                      ? 'white'
                      : AppColor.PrimaryColor,
                }}>
                {x.status} {moment(x.time).format('h:mm a')}
              </Text>
            </TouchableOpacity>
            {
              props.currentMessage?.sms?.length && props.currentMessage.sms.map((x, index) => {
                return(
                  <>
                  {
                    x.sms !== "" && 
                <View
                  key={`message_${index}`}
                  
                  activeOpacity={0.5}
                  style={props.currentMessage.user._id == 2
                    ? CommonStyle.left
                    : CommonStyle.right}>
                  <Text
                    style={props.currentMessage.user._id == 1
                      ? CommonStyle.bubbleRightText
                      : CommonStyle.bubbleLeftText}>
                    {x.sms}
                  </Text>
                  <Text
                    style={{
                      textAlign: props.currentMessage.user._id == 1 ? 'right' : 'right',
                      fontSize: 12,
                      fontFamily: AppFonts.Roboto_Regular,
                      color: props.currentMessage.user._id == 1
                        ? 'white'
                        : AppColor.PrimaryColor,
                    }}>
                    {props.currentMessage.senderName == ''
                      ? ''
                      : '(sent by ' + props.currentMessage.senderName + ')'}{' '}
                    {x.sms_status} {moment(x.time).format('h:mm a')}
                  </Text>
                </View>
                  }
                  </>
                )
                })
            }
            </>
          ))}
        {/* recordings */}
        {props.currentMessage.records.length > 0 &&
          props.currentMessage.records.map((x) => (
            <TouchableOpacity
              onPress={() => {
                if (x?.recording_url) {
                  Linking.openURL(x?.recording_url);
                }
              }}
              activeOpacity={0.6}
              style={
                props.currentMessage.user._id == 2
                  ? CommonStyle.left
                  : CommonStyle.right
              }>
              <Entypo name="mic" color={AppColor.PrimaryColor} size={40} />
              <audio src={x.recording_url} controls/>
              <Text
                style={{
                  textAlign:
                    props.currentMessage.user._id == 1 ? 'right' : 'right',
                  fontSize: 12,
                  fontFamily: AppFonts.Roboto_Regular,
                  color:
                    props.currentMessage.user._id == 1
                      ? 'white'
                      : AppColor.PrimaryColor,
                }}>
                {(x.recording_duration / 60).toFixed(2)} min
              </Text>
              <Text
                style={{
                  textAlign:
                    props.currentMessage.user._id == 1 ? 'right' : 'right',
                  fontSize: 12,
                  fontFamily: AppFonts.Roboto_Regular,
                  color:
                    props.currentMessage.user._id == 1
                      ? 'white'
                      : AppColor.PrimaryColor,
                }}>
                {x.status} {moment(x.time).format('h:mm a')}
              </Text>
            </TouchableOpacity>
          ))}
        {(tenant?.transcribe && props.currentMessage.transcripts.length > 0) && props.currentMessage.transcripts.map(x => {
            return(
            <TouchableOpacity onPress={() => {
                handleShowTranscript(x.comm);
            }} activeOpacity={0.5} style={[props.currentMessage.user._id === 2 ? CommonStyle.left : CommonStyle.right, {flexDirection:'row',alignItems:'center',marginBottom:20}]}>
              <Entypo name='message' size={32} color={AppColor.PrimaryColor} />
              <Text style={{marginLeft:7,fontSize:16,color:AppColor.PrimaryColor}}>View Transcript</Text>
                
            </TouchableOpacity>)
          })
        } 
      </View>
      <Modal
        useNativeDriver={true}
        visible={modal}
        onBackdropPress={() => setModal(false)}>
        <ScrollView style={{ backgroundColor: 'white', marginHorizontal: 10 }}>
          <Text
            style={{
              fontSize: 20,
              textAlign: 'center',
              borderBottomWidth: 0.5,
              borderBottomColor: AppColor.Dark,
              paddingBottom: 10,
              marginVertical: 10,
            }}>
            Message Options
          </Text>
          <Text
            style={{
              fontSize: 20,
              textAlign: 'center',
              borderBottomWidth: 0.5,
              borderBottomColor: AppColor.Dark,
              paddingBottom: 10,
              marginVertical: 10,
            }}>
              {text}
            </Text>
          <TouchableOpacity
          accessible={true}
          accessibilityLabel='Copy message text'
            onPress={() => {
              setModal(false);
              Clipboard.setString(text);
            }}
            style={{
              paddingVertical: 10,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
            <Icon type="MaterialIcons" name="content-copy" />
            <Text style={{ fontSize: 15, fontFamily: AppFonts.Roboto_Regular }}>
              Copy message text
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
          accessible={true}
          accessibilityLabel='Copy message text'
            onPress={() => {
              setModal(false);
            }}
            style={{
              paddingVertical: 10,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
            <Text style={{ fontSize: 15, fontFamily: AppFonts.Roboto_Regular }}>
              Cancel
            </Text>
          </TouchableOpacity>
          {/* <TouchableOpacity onPress={()=>{
                 setModal(false)
                 Clipboard.setString(text);
            }} style={{paddingVertical:10,justifyContent:"center",alignItems:"center",flexDirection:"row"}}>
                <Icon type="MaterialIcons" name="content-copy" />
                <Text style={{fontSize:15,fontFamily:AppFonts.Roboto_Regular}}></Text>
            </TouchableOpacity> */}
        </ScrollView>
      </Modal>

    </>
  );
};

const RenderPhoneModal = (props) => {
  return (
    <View
      style={{ flex: 1, justifyContent: 'center', backgroundColor: '#0005' }}>
      <View
        style={{
          height: 270,
          paddingHorizontal: 30,
          width: '60%',
          alignSelf: 'center',
          backgroundColor: 'white',
          marginBottom: 48,
          borderRadius: 20,
          borderColor: AppColor.PrimaryColor,
          borderWidth: 1,
        }}>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 20,
            textAlign: 'center',
            marginTop: 10,
          }}>
          Call Contact
        </Text>
        <View
          style={{
            height: 1,
            backgroundColor: AppColor.PrimaryColor,
            marginVertical: 10,
          }}></View>
        <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 15 }}>
          Choose the number you would like to call
        </Text>
        <ScrollView style={{ marginVertical: 10 }}>
          {props.phoneNumbers.map((x) => {
            return (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Text
                  style={{
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 20,
                    color: AppColor.PrimaryColor,
                  }}>
                  {x.countryCode}
                  {x.longCode}
                </Text>
                <MaterialIcons
                  onPress={() => {
                    props.setPhoneModalVisible(false);
                    props.handleCall(x.countryCode + x.longCode);
                  }}
                  name="phone"
                  size={30}
                  color={AppColor.PrimaryColor}
                />
              </View>
            );
          })}
        </ScrollView>
        <TouchableOpacity
          onPress={() => props.setPhoneModalVisible(false)}
          style={{
            padding: 10,
            backgroundColor: AppColor.PrimaryColor,
            borderRadius: 5,
            marginBottom: 5,
          }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              color: AppColor.SecondaryColor,
              fontSize: 20,
              textAlign: 'center',
            }}>
            Cancel
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const CommonStyle = StyleSheet.create({
  AuthContainer: {
    flex: 1,
    backgroundColor: 'black',
  },
  headerTextStyle: {
    padding: 10,
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    color: 'black',
    fontSize: 2,
    fontWeight: '500',
  },
  buttonGroupContainerStyle: {
    backgroundColor: 'black',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    height: 5,
  },
  disableTextStyle: {
    color: 'black',
  },
  selectedTextStyle: {
    color: 'black',
  },
  seperatorStyle: {
    height: 1,
    width: '86%',
    backgroundColor: 'black',
    alignSelf: 'center',
  },

  dayText: {
    color: 'grey',
    fontSize: 13,
  },
  left: {
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    paddingVertical: 4,
    marginBottom: 2,
    paddingHorizontal: 10,
    minWidth: 70,
    maxWidth: wp(93),
  },
  right: {
    backgroundColor: AppColor.PrimaryColor + 'CA',
    borderRadius: 8,
    paddingVertical: 4,
    marginBottom: 2,
    paddingHorizontal: 10,
    minWidth: 70,
  },
  leftImage: {
    backgroundColor: 'black',
    borderColor: 'black',
    borderWidth: 2.2,
  },

  rightImage: {
    backgroundColor: 'black',
    borderColor: 'black',
    borderWidth: 2.2,
    padding: 5,
  },

  bubbleLeftText: {
    color: 'black',
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginTop: 1,
  },
  bubbleRightText: {
    color: AppColor.SecondaryColor,
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginTop: 1,
  },

  inputContainer: {
    minHeight: 6,
    borderTopWidth: 2,
    borderColor: 'black',
    alignItems: 'center',
  },
  inputPrimary: {
    backgroundColor: 'black',
    marginVertical: 4,
    marginHorizontal: 16,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendContainer: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: '#f9f9f9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  send: {
    width: 20,
    height: 20,
  },
  composerText: {
    color: AppColor.SecondaryColor,
    fontSize: 14,
  },
  timeTextLeft: {
    color: 'black',
    fontSize: 12,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'right',
  },
  timeTextRight: {
    color: AppColor.SecondaryColor,
    fontSize: 12,
  },
  loadEarlierText: {
    color: 'white',
    fontSize: 12,
  },
  modalitemsStyle: { alignItems: 'center', width: '33%' },
  modaltextStyle: {
    color: 'black',
    fontSize: 12,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'center',
  },
});

export default Chats;
