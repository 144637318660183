import React from 'react';
import { Text, View } from 'react-native';
import AppFonts from '../../../../Constants/Fonts';
import AppColors from '../../../../Constants/Color';
import IntegrationComponent from './IntegrationComponent';
export default function SamlComponent({
  provider,
  integration,
}) {
  let label =
    'Please enable and save to connect to' + provider.label;

  return (
    <>
      <View style={{ marginHorizontal: 15 }}>
        <Text
          style={{
            color: AppColors.Dark,
            fontSize: 18,
            marginBottom: 10,
            fontFamily: AppFonts.Roboto_Regular,
            letterSpacing: 1,
          }}>
          {label}
        </Text>
        <IntegrationComponent provider={provider} integration={integration} />
      </View>
    </>
  );
}
