import React from 'react';
import { Text, View } from 'react-native';
import { useToast } from 'native-base';
import { SafeAreaView } from 'react-native-safe-area-context';

import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import End_Points from '../../../Constants/Api';
import RequestMaker from '../../../Middleware/ApiCaller';
import getData from '../../../AsyncUtils/GetData';
import Loader from '../../../Components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../Redux';
import { useFocusEffect } from '@react-navigation/native';

const ChangePassword = ({ navigation }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [p1, setP1] = React.useState('');
  const [p2, setP2] = React.useState('');
  const [p3, setP3] = React.useState('');
  const { profile } = useSelector((state) => state.User);

  const Toast = useToast();
  const userId = useSelector((state) => state.User)?.profile?.user?.user_id;

  const handleChangePassword = async () => {
    setLoading(true);
    if (p1.trim().length >= 8 && /[A-Z]/.test(p1) && /[a-z]/.test(p1)) {
      if (p1.trim() == p2.trim()) {
        try {
          const body = {
            action: 'update_user',
            userId: userId,
            currentPassword: p3.trim(),
            password: p1.trim(),
          };

          
          let device = await getData('@device_id');
          const headers = { 'x-device': device };
          let Config = {
            method: 'post',
            url: End_Points.Base_url + End_Points.End,
            data: body,
            headers: headers,
            redirect: 'follow',
          };
          let result = await RequestMaker(Config);
          if (result.data.success) {
            dispatch(setUser({passwordResetRequired: false}))
            Toast.show({
              duration: 2000,
              title: 'Password successfully changed.',
              status: 'success',
            });
            navigation.goBack();
          } else if(result.data.errorMessage){
            Toast.show({
              title: result.data.errorMessage,
              status: 'error',
            });
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        Toast.show({
          duration: 2000,
          title: 'Confirm the password',
          status: 'success',
        });
      }
    } else {
      Toast.show({
        duration: 2000,
        title: 'Verify the requirement for the password',
        status: 'info',
      });
    }

    setLoading(false);
  };


  React.useEffect(()=>{

      const checkIfSSO = async () => {
        try{
          setLoading(true);
          if(!profile?.user?.passwordResetRequired) {
            setLoading(false);
            return;
          }
          
          const body = {
            action: 'lookup_user',
            username: profile?.user?.email,
            toast: false            
          };

          const result = await RequestMaker(body);

          if(result.data?.success){
            if(result.data?.accounttype && result?.data?.accounttype === 'sso'){
              dispatch(setUser({passwordResetRequired: false}))
            }
          }
          setLoading(false);

        } catch(err){
          console.log(err);
        }
      }

      checkIfSSO();

    }, [])


  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={{ flex: 1, width: '100%', alignSelf: 'center', padding: 30 }}>
        <View style={{ marginBottom: 20, marginTop: 5 }}>
          <Text
            style={{
              fontSize: 14,
              color: AppColor.Dark,
              fontFamily: AppFonts.Roboto_Regular,
            }}>
            Password Requirements
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: AppColor.Dark,
              marginLeft: 5,
              fontFamily: AppFonts.Roboto_Regular,
            }}>
            + at least 8 characters long
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: AppColor.Dark,
              marginLeft: 5,
              fontFamily: AppFonts.Roboto_Regular,
            }}>
            + at least one uppercase character
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: AppColor.Dark,
              marginLeft: 5,
              fontFamily: AppFonts.Roboto_Regular,
            }}>
            + at least one lowercase character
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: AppColor.Dark,
              marginLeft: 5,
              fontFamily: AppFonts.Roboto_Regular,
            }}>
            + at least one number character
          </Text>
        </View>
        <CustomInput
        secure={true}
          value={p3}
          onChangeText={(t) => setP3(t)}
          placeholder={'Enter Current Password'}
          title={'Current Password'}
        />

        <CustomInput
        secure={true}
          value={p1}
          onChangeText={(t) => setP1(t)}
          placeholder={'Enter New Password'}
          title={'New Password'}
        />
        <View style={{ height: 10 }} />
        <CustomInput
        secure={true}
          value={p2}
          onChangeText={(t) => setP2(t)}
          placeholder={'Confirm New Password'}
          title={'Confirm Password'}
        />
        <View style={{ height: 30 }} />
        <CustomButton
        width={'20%'}
        style={{alignSelf:'flex-end'}}
          title={'Change Password'}
          onPress={handleChangePassword}
        />
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default ChangePassword;
