import * as React from 'react';
import {
  Text,
  View,
  Platform,
  Pressable,
  StyleSheet,
  Modal,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDrawerNavigator,
} from '@react-navigation/drawer';
import { Icon } from 'react-native-elements';
import IdleTimer from 'react-idle-timer';
import { DrawerActions } from '@react-navigation/routers';
import SelectBookContact from '../Screens/Bookmarks/SelectBookContact'
import AppFonts from '../Constants/Fonts';
import Dashboard from '../Screens/Dashboard';
import Calculators from '../Screens/Calculators';
import Templates from '../Screens/Templates';
import DialPad from '../Screens/Dialpad';
import AutoResponse from '../Screens/AutoResponse';
import Bookmarks from '../Screens/Bookmarks';
import Messages from '../Screens/Messages';
import ScheduledMessage from '../Screens/ScheduledMessage';

import Settings from '../Screens/Settings';
import Vcard from '../Screens/Profile/Vcard';
import ChangePassword from '../Screens/Profile/ChangePassword';
import ForwardingNumber from '../Screens/Preference/ForwardingNumber';
import OutOfOffice from '../Screens/Preference/Out_Of_Office';
import Timezone from '../Screens/Preference/Timezone';
import BillingManagement from '../Screens/AddBillings/BillingManagement';
import ArchiveAddOn from '../Screens/AddBillings/Archive_Add_On';
import Accounts from '../Screens/AddBillings/Accounts';
import Crm from '../Screens/Integration/Crm';
import Archive from '../Screens/Integration/Archive';
import AddAutoResponse from '../Screens/AutoResponse/AddAutoResponse';
import AddTemplates from '../Screens/Templates/AddTemplates';
import AddBookmarks from '../Screens/Bookmarks/AddBookmarks';
import VoiceAddOn from '../Screens/AddBillings/VoiceAddOn';
import CallerId from '../Screens/AddBillings/VoiceAddOn/CallerId';
import CallerTimeout from '../Screens/AddBillings/VoiceAddOn/CallerTimeout';
import VoiceAvailability from '../Screens/AddBillings/VoiceAddOn/VoiceAvailability';
import VoiceMail from '../Screens/AddBillings/VoiceAddOn/VoiceMail';
import VoiceNumber from '../Screens/AddBillings/VoiceAddOn/VoiceNumber';
import RecordingAddOn from '../Screens/AddBillings/RecordingAddOn';
import TranscribeAddOn from '../Screens/AddBillings/TranscribeAddOn';
import AddContacts from '../Screens/Contacts/AddContacts';
import Chats from '../Screens/Chat';
import RecentMedia from '../Screens/Chat/RecentMedia';
import SelectTemplates from '../Screens/Chat/SelectTemplates';
import DistributionDetail from '../Screens/Distributions/DistributionDetail';
import AllContacts from '../Screens/Contacts/contacts';
import Distributions from '../Screens/Distributions';
import StartConversation from '../Screens/Messages/StartConversation';
import WebView from '../Screens/WebView';
import AddScheduledMessage from '../Screens/ScheduledMessage/AddScheduledMessage';
import SelectContactForSchedule from '../Screens/ScheduledMessage/SelectContactForSchedule';
import ScheduleContactMessage from '../Screens/Chat/ScheduleContactMessage';
import BlockedContacts from '../Screens/Contacts/BlockedContacts';
import DuplicateContacts from '../Screens/Contacts/DuplicateContacts';
import ContactPhonePolicy from '../Screens/Contacts/ContactPhonePolicy';
import ImportFromCrm from '../Screens/Contacts/ImportFromCrm';
import ContactEdit from '../Screens/Contacts/ContactEdit';
import AddDL from '../Screens/Contacts/AddDL';
import ContactFromPhone from '../Screens/Contacts/ContactFromPhone';
import DLList from '../Screens/Contacts/DLList';
import AddPhoneNumber from '../Screens/Contacts/AddPhoneNumber';
import DialpadSearch from '../Screens/Dialpad/DialpadSearch';
import Integrations from '../Screens/Settings/Integrations';
import Support from '../Screens/Support/index';
import TrainingVideos from '../Screens/Support/TrainingVideos';
import ContactUs from '../Screens/Support/ContactUs';
import TermOfUse from '../Screens/Support/TermOfUse';
import MobileNotifications from '../Screens/Support/MobileNotifications';
import NotificationStatus from '../Screens/Support/NotificationStatus';
import NotificationTest from '../Screens/Support/NotificationTest';
import SearchMessages from '../Screens/Messages/SearchMessages';
import OAuthView from '../Screens/Settings/Integrations/ui/OAuthView';
import ContentScreen from '../Screens/Chat/ContentScreen';
import AccountReport from '../Screens/ArchivesReports/AccountReport';
import ArchiveAudit from '../Screens/ArchivesReports/ArchiveAudit';
import ArchiveLayout from '../Screens/ArchivesReports/ArchiveLayout';
import ArchiveSchedule from '../Screens/ArchivesReports/ArchiveSchedule';
import ConnectArchiver from '../Screens/ArchivesReports/ConnectArchiver';
import AppOptions from '../Screens/Profile/AppOptions';
import PositiveConsent from '../Screens/Profile/PositiveConsent';

import RequestMaker from '../Middleware/ApiCaller';
import { clearProfile } from '../Redux';
import {
  setTimeOutThunk,
} from '../Redux/account_active';
import APIAccess from '../Screens/Profile/APIAccess';
import Accessibility from '../Screens/Support/Accessibility';
import AccountsPreview from './AccountsPreview';
import DrawerMenuItem from './DrawerMenuItem';
import { RemovePhoneModal } from '../Screens/Contacts/modals';
import FeedbackWidget from './FeedbackWidget';
import DrawerHeader from './DrawerHeader';
import SelectBookmark from '../Screens/Bookmarks/SelectBookmark';
import useResponsiveLayout from '../Middleware/useResponsiveLayout';
const Drawerscreen = createDrawerNavigator();

export default function Drawer({ navigation, route }) {
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [inactiveTimeout, setInactiveTimeout] = React.useState(1000 * 60 * 10);
  const { inactivity } = useSelector((state) => state.accountActivity);
  const { profile } = useSelector((state) => state.User);
  const recent_messages = useSelector((state) => state.recent_messages).data;
  const totalUnreadMessages = recent_messages.reduce((total, item) => total + item.unreadmessages, 0);
  const group = useSelector((state) => state.User?.profile?.group);
  const dispatch = useDispatch();
  const idleTimer = React.useRef();
  const {isWebCompact} = useResponsiveLayout();

  const navRef = React.useRef();

  const [logOutModal, setLogOutModal] = React.useState(false);
  const [feedback, setFeedback] = React.useState(false);

  React.useEffect(() => {
    const timeout =
      profile?.group?.inactivityTimeout ||
      profile?.affiliate?.inactivityTimeout;
    if (timeout) {
      setInactiveTimeout(timeout * 1000 * 60);
    }
  }, [profile]);


  const onAction = () => {
    dispatch(setTimeOutThunk(false));
  };

  const _onActive = () => {
    dispatch(setTimeOutThunk(false));
  };

  const _onIdle = () => {
    idleTimer.current?.reset();

    dispatch(setTimeOutThunk(true));
  };


  const title = (text) =>
    Platform.select({ web: `${text}`, default: text });

  const CustomSidebarMenu = (props) => {

    return (
      <AccountsPreview {...props} />
    );
  };

  const confirmLogout = async () => {
    const body = {
      action: 'logout',
    };
    let result = await RequestMaker(body);
    if (result.status == 200) {
        if(route.params.onSwitchAccount){            
          route.params.onSwitchAccount();
        }
        dispatch(clearProfile());
        navigation.dispatch(DrawerActions.closeDrawer());
        navigation.navigate('Login');
    }
  }


  let calcualtor_enabled = 'disabled'
  if (group?.appCalculators == true) calcualtor_enabled = 'Calculators'

  
  return (
    <>
    <Drawerscreen.Navigator
      ref={navRef}
      initialRouteName="Messages"
      drawerPosition="left"
      drawerType='permanent'
      overlayColor="transparent"
      
      drawerContent={(props) => {
        const filteredProps = {
          ...props,
          state: {
            ...props.state,
            routeNames: props.state.routeNames.filter((routeName) => {
              routeName === 'Dashboard' ||
                routeName === 'Messages' ||
                routeName === 'Dialpad' ||
                routeName === 'Contacts' ||
                routeName === 'ScheduledMessage' ||
                routeName === 'Templates' ||
                routeName === 'AutoResponse' ||
                routeName === 'Bookmarks' ||
                routeName === 'Calculators';
            }),
            routes: props.state.routes.filter(
              (route) =>
                route.name === 'Dashboard' ||
                route.name === 'Messages' ||
                route.name === 'Dialpad' ||
                route.name === 'Contacts' ||
                route.name === 'ScheduledMessage' ||
                route.name === 'Templates' ||
                route.name === 'AutoResponse' ||
                route.name === 'Bookmarks' ||
                route.name === 'Calculators'
            ),
          },
        };
        return (
          <>
            {inactivity && (
              <IdleTimer
                ref={idleTimer}
                element={document}
                onActive={_onActive}
                onIdle={_onIdle}
                onAction={onAction}
                debounce={1000}
                timeout={inactiveTimeout}
              />
            )}

            <CustomSidebarMenu
              navigation={navigation}
              setDrawerOpen={(x) => setDrawerOpen(x)}
              isdrawerOpen={drawerOpen}
              {...filteredProps}
            />
            <View style={{ backgroundColor: '#20508A',paddingVertical:10 }}>
              <DrawerMenuItem label="Accessibility" iconName='accessibility' iconType='ionicons' onPress={() => { navigation.navigate('Accessibility') }} />
              <DrawerMenuItem label='Settings' iconName='gear' iconType='evilicon' onPress={() => {
                console.log(navRef.current);
                navigation.navigate('Settings')
              }} />
              <DrawerMenuItem label='Support' iconName='help-outline' iconType='MaterialIcons' onPress={() => { navigation.navigate('Support') }} />
            </View>
          </>
        );
      }}
      screenOptions={{
        headerShown:true,
        headerTitleAlign:'left',
        headerLeft:() => <Icon name='back' style={{paddingRight:0,marginLeft:15}} type='antdesign' color='white' onPress={() => navigation.goBack()} />,
        headerRight:()=> 
        <DrawerHeader
        onSwitchAccount={() => {
          
          if(route.params.onSwitchAccount){            
            route.params.onSwitchAccount();
          }
        }}
        onLogout={() => setLogOutModal(true)}
        onFeedback={() => setFeedback(true)}
        />,
        headerTitleStyle:{color:'white',fontWeight:400,fontSize:20,marginLeft:0,paddingLeft:0},
        headerStyle:{height:70,backgroundColor:'#20508A',paddingLeft:5,paddingRight:20}
      
      }}

      drawerStyle={{
        maxWidth: !isWebCompact ? 250 : 75,
        maxHeight: '100vh',
        overflow:'hidden',
        paddingTop: 0,
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: 0
      }}
      drawerContentOptions={{
        
        labelStyle: {
          color: 'white',
          fontFamily: AppFonts.Roboto_Regular,
          
        },
        contentContainerStyle: {
          paddingTop: 0,
        },
        activeTintColor: 'white',
        activeBackgroundColor: '#3F3F3F',
        itemStyle: {
          marginLeft: 0,
          width: '100%',
          borderColor: '#598527',
          marginVertical: 0,
          borderRadius: 0,
          paddingBottom:0,
          paddingLeft: 18,
          alignItems: 'flex-start',
        },
      }}>
      <Drawerscreen.Screen
        name="Dashboard"
        component={Dashboard}
        options={{
          headerLeft: () => null,
          alignItems: 'flex-start',
          drawerLabel: ({ focused }) => <DrawerLabel label="Dashboard" focused={focused} />,
          title: 'Dashboard',
          drawerIcon: ({ focused }) => <DrawerIcon focused={focused} iconType='entypo' iconName='gauge' />
        }}
      />
      <Drawerscreen.Screen
        name="Messages"
        component={Messages}
        options={{
          headerLeft: () => null,
          drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Messages'} showIndicator={totalUnreadMessages} />,
          title: 'Messages',
          drawerIcon: ({ focused }) => <DrawerIcon iconType='antdesign' iconName='message1' focused={focused} />
        }}
      />
      <Drawerscreen.Screen
        name="Dialpad"
        component={DialPad}
        options={{
          headerLeft: () => null,
          drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label='Calls' />,
          title: 'Calls',
          drawerIcon: ({ focused }) => <DrawerIcon focused={focused} iconType='MaterialCommunityIcons' iconName='phone' />
        }}
      />
      <Drawerscreen.Screen
        name="Contacts"
        
        initialParams={{ type: '' }}
        component={AllContacts}
        options={{
          headerLeft: () => null,
          drawerLabel: ({ focused }) =>
            !isWebCompact ?
            <>
              <DrawerLabel label='Contacts' focused={focused} expandable={true} />
              {focused &&
                <>
                  <Pressable style={[ styles.expandableMenuItem ,{ marginTop: 10 }]} nativeID='link-all' onPress={e => { e.preventDefault(); navigation.navigate('Contacts', { type: '' }) }}> <Text style={{fontFamily:AppFonts.Roboto_Regular}}>All Contacts</Text></Pressable>
                  <Pressable style={styles.expandableMenuItem} nativeID='link-duplicate' onPress={e => { e.preventDefault(); navigation.navigate('Contacts', { type: 'Duplicate' }) }}> <Text style={{fontFamily:AppFonts.Roboto_Regular}}>Duplicates</Text></Pressable>
                  <Pressable style={styles.expandableMenuItem} nativeID='link-blocked' onPress={e => { e.preventDefault(); navigation.navigate('Contacts', { type: 'Blocked' }) }}> <Text style={{fontFamily:AppFonts.Roboto_Regular}} >Blocked</Text></Pressable>
                  <Pressable style={styles.expandableMenuItem} nativeID='link-distributions' onPress={e => { e.preventDefault(); navigation.navigate('Contacts', { type: 'Distribution Lists' }) }}> <Text style={{fontFamily:AppFonts.Roboto_Regular}}>Distribution Lists</Text></Pressable>
                </>

              }
            </>
            :
            <DrawerLabel focused={focused} label='Contacts' />
          ,
          title: 'Contacts',
          drawerIcon: ({ focused }) => <DrawerIcon expandable={true} focused={focused} iconType='antdesign' iconName='contacts' />
        }}
      />

      <Drawerscreen.Screen
        name="ScheduledMessage"
        component={ScheduledMessage}
        options={{
          headerLeft: () => null,
          drawerLabel: ({ focused }) => <DrawerLabel label='Scheduled' focused={focused} />,
          title: 'Scheduled',
          drawerIcon: ({ focused }) => <DrawerIcon focused={focused} iconName='calendar' iconType='antdesign' />,
        }}
      />
      <Drawerscreen.Screen
        name="Templates"
        component={Templates}
        options={{
          headerLeft: () => null,
          drawerLabel: ({ focused }) => <DrawerLabel label='Templates' focused={focused} />,
          title: 'Templates',
          drawerIcon: ({ focused }) => <DrawerIcon focused={focused} iconType='antdesign' iconName='copy1' />
        }}
      />
      <Drawerscreen.Screen
        name="AutoResponse"
        component={AutoResponse}
        options={{
          headerLeft: () => null,
          drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label='Auto Responses' />,
          title: 'Auto Response',
          drawerIcon: ({ focused }) => <DrawerIcon focused={focused} iconName='autorenew' iconType='material-icon' />
        }}
      />
      <Drawerscreen.Screen
        name="Bookmarks"
        component={Bookmarks}
        options={{
          headerLeft: () => null,
          drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label='Bookmarks' />,
          title: 'Bookmarks',
          drawerIcon: ({ focused }) => <DrawerIcon focused={focused} iconName='book' iconType='antdesign' />
        }}
      />
      <Drawerscreen.Screen
        name={calcualtor_enabled}
        component={Calculators}
        options={{
          headerLeft: () => null,
          drawerLabel: ({ focused }) => <DrawerLabel label='Calculator' focused={focused} />,
          title: 'Calculators',
          drawerIcon: ({ focused }) => <DrawerIcon focused={focused} iconName='calculator' iconType='antdesign' />
        }}
      />
      <Drawerscreen.Screen
        name="BlockedContacts"
        component={BlockedContacts}
        options={{ drawerLabel: title('BlockedContacts') }}
      />
      <Drawerscreen.Screen
        name="DuplicateContacts"
        component={DuplicateContacts}
        options={{ drawerLabel: title('Contacts') }}
      />
      <Drawerscreen.Screen
        name="AllContacts"
        component={AllContacts}
        options={{ drawerLabel: title('All Contacts') }}
      />
      <Drawerscreen.Screen
        name="Distributions"
        component={Distributions}
        options={{ drawerLabel: title('Distributions') }}
      />
      <Drawerscreen.Screen
        name="AddContacts"
        component={AddContacts}
        options={{ drawerLabel: title('AddContacts') }}
      />
      <Drawerscreen.Screen
        name="ContactPhonePolicy"
        component={ContactPhonePolicy}
        options={{ drawerLabel: title('Contact Phone Policy') }}
      />
      <Drawerscreen.Screen
        name="ContactFromPhone"
        component={ContactFromPhone}
        options={{ drawerLabel: title('ContactFromPhone') }}
      />
      <Drawerscreen.Screen
        name="ImportFromCrm"
        component={ImportFromCrm}
        options={{ title: title('Import From CRM'), drawerLabel: title('Login'), unmountOnBlur: true }}
      />
      <Drawerscreen.Screen
        name="ContactEdit"
        component={ContactEdit}
        options={{ title: title('Contact Edit') }}
      />
      <Drawerscreen.Screen
        name="AddPhoneNumber"
        component={AddPhoneNumber}
        options={{ drawerLabel: title('Add PhoneNumber') }}
      />
      <Drawerscreen.Screen
        name="DLList"
        component={DLList}
        options={{ drawerLabel: title('DLList') }}
      />
      <Drawerscreen.Screen
        name="AddDL"
        component={AddDL}

        options={{ title: title('Add Distribution List'), unmountOnBlur: true }}
      />
      <Drawerscreen.Screen
        name="DistributionDetail"
        component={DistributionDetail}
        options={{ drawerLabel: title('Distribution Detail') }}
      />
      <Drawerscreen.Screen
        name="DialpadSearch"
        component={DialpadSearch}
        options={{ title: title('Search') }}
      />
      <Drawerscreen.Screen
        name="AddScheduledMessage"
        component={AddScheduledMessage}
        options={{ drawerLabel: title('AddScheduledMessage') }}
      />
      <Drawerscreen.Screen name="SelectTemplates" component={SelectTemplates} />
      <Drawerscreen.Screen
        name="SelectContactForSchedule"
        component={SelectContactForSchedule}
        options={{ title: title('Select Contact for Scheduled Message') }}

      />
      <Drawerscreen.Screen name="AddTemplates" component={AddTemplates} />
      <Drawerscreen.Screen name="WebView" component={WebView} />
      <Drawerscreen.Screen name="AddBookmarks" component={AddBookmarks} />
      <Drawerscreen.Screen name="AddAutoResponse" component={AddAutoResponse} />
      <Drawerscreen.Screen
        name="StartConversation"
        component={StartConversation}
        options={{ unmountOnBlur: true, title: title('Start Conversation') }}
      />
      <Drawerscreen.Screen
        name="Chats"
        component={Chats}
        options={{ title: title('Chats') }}
      />
      <Drawerscreen.Screen
        name="RecentMedia"
        component={RecentMedia}
        options={{ title: title('Recent Media') }}
      />
      <Drawerscreen.Screen
        name="SearchMessages"
        component={SearchMessages}
        options={{ title: title('Search Messages') }}
      />
      <Drawerscreen.Screen
        name="Settings"
        component={Settings}
        options={{ title: title('Settings'),
        headerLeft: () => null,
      }}
      />
      <Drawerscreen.Screen
        name="Vcard"
        component={Vcard}
        options={{ title: title('VCard') }}
      />
      <Drawerscreen.Screen
        name="ChangePassword"
        component={ChangePassword}
        options={{ title: title('ChangePassword'), unmountOnBlur:true}}
      />
      <Drawerscreen.Screen
        name="ForwardingNumber"
        component={ForwardingNumber}
        options={{ title: title('Forwarding Number') }}
      />
      <Drawerscreen.Screen
        name="OutOfOffice"
        component={OutOfOffice}
        options={{ title: title('Out Of Office') }}
      />
      <Drawerscreen.Screen
        name="Timezone"
        component={Timezone}
        options={{ title: title('Timezone') }}
      />
      <Drawerscreen.Screen
        name="SelectBookContact"
        component={SelectBookContact}
        options={{ title: title('Select Book Contact') }}
      />
      <Drawerscreen.Screen
        name="SelectBookmark"
        component={SelectBookmark}
        options={{ title: title('Select Bookmark') }}
      />
      <Drawerscreen.Screen
        name="VoiceAddOn"
        component={VoiceAddOn}
        options={{ title: title('Voice Add-On') }}
      />
      <Drawerscreen.Screen name="CallerId" options={{title: title('Caller ID')}} component={CallerId} />
      <Drawerscreen.Screen name="CallerTimeout" options={{title: title('Caller Timeout')}} component={CallerTimeout} />
      <Drawerscreen.Screen
        name="VoiceAvailability"
        options={{title: title('Voice Availability')}}
        component={VoiceAvailability}
      />
      <Drawerscreen.Screen options={{title: title('Voice Mail')}} name="VoiceMail" component={VoiceMail} />
      <Drawerscreen.Screen name="VoiceNumber" options={{title: title('Voice Number')}} component={VoiceNumber} />
      <Drawerscreen.Screen options={{title: title('Account Report')}} name="AccountReport" component={AccountReport} />
      <Drawerscreen.Screen options={{title: title('Archive Audit')}} name="ArchiveAudit" component={ArchiveAudit} />
      <Drawerscreen.Screen options={{title: title('Archive Layout')}} name="ArchiveLayout" component={ArchiveLayout} />
      <Drawerscreen.Screen options={{title: title('Archive Schedule')}} name="ArchiveSchedule" component={ArchiveSchedule} />
      <Drawerscreen.Screen 
      options={{ title: title('Connect to Archiver') }}

      name="ConnectArchiver" component={ConnectArchiver} />
      <Drawerscreen.Screen
        name="RecordingAddOn"
        component={RecordingAddOn}
        options={{ title: title('Recording Add-On') }}
      />
      <Drawerscreen.Screen
        name="TranscribeAddOn"
        component={TranscribeAddOn}
        options={{ title: title('Transcribe Add-On') }}
      />
      <Drawerscreen.Screen
        name="Accounts"
        component={Accounts}
        options={{ title: title('Accounts') }}
      />
      <Drawerscreen.Screen
        name="ArchiveAddOn"
        component={ArchiveAddOn}
        options={{ title: title('Archive Add-On') }}
      />
      <Drawerscreen.Screen
        name="BillingManagement"
        component={BillingManagement}
        options={{ title: title('Billing Management') }}
      />
      <Drawerscreen.Screen
        name="Support"
        component={Support}
        options={{ title: title('Support'),
        headerLeft: () => null,
      }}
      />
      <Drawerscreen.Screen
        name="ContactUs"
        component={ContactUs}
        options={{ title: title('ContactUs') }}
      />
      <Drawerscreen.Screen
        name="TermOfUse"
        component={TermOfUse}
        options={{ title: title('TermOfUse') }}
      />
      <Drawerscreen.Screen
        name="MobileNotifications"
        component={MobileNotifications}
        options={{ title: title('Mobile Notifications') }}
      />
      <Drawerscreen.Screen
        name="NotificationStatus"
        component={NotificationStatus}
        options={{ title: title('Notification Status') }}
      />
      <Drawerscreen.Screen
        name="NotificationTest"
        component={NotificationTest}
        options={{ title: title('Notification Test') }}
      />
      <Drawerscreen.Screen
        name="TrainingVideos"
        component={TrainingVideos}
        options={{ title: title('Training Videos') }}
      />
      <Drawerscreen.Screen
        name="Integrations"
        component={Integrations}
        options={{ title: title('Integrations') }}
      />
      <Drawerscreen.Screen
        name="Crm"
        component={Crm}
        options={{ title: title('CRM') }}
      />
      <Drawerscreen.Screen
        name="Archive"
        component={Archive}
        options={{ title: title('Archive') }}
      />
      <Drawerscreen.Screen
        name="OAuthView"
        component={OAuthView}
        options={{ title: title('OAuthView') }}
      />
      <Drawerscreen.Screen
        name="ScheduleContactMessage"
        component={ScheduleContactMessage}
        options={{ title: title('ScheduleContactMessage') }}
      />
      <Drawerscreen.Screen
        name="ContentScreen"
        component={ContentScreen}
        options={{ title: title('Content') }}
      />

      <Drawerscreen.Screen options={{title: title('App Options')}} name="AppOptions" component={AppOptions} />
      <Drawerscreen.Screen 
      options={{ title: title('Positive Content') }}
      name="PositiveConsent" 
      component={PositiveConsent} />
      <Drawerscreen.Screen 
      options={{ title: title('API Access') }}
      name="APIAccess" component={APIAccess} />
      <Drawerscreen.Screen options={{
        title: '',
                  headerLeft: () => null,
      }} name="Accessibility" component={Accessibility} />
    </Drawerscreen.Navigator>
        {
        logOutModal && 
        <Modal style={{position:'absolute',top:0,left:0,height:'100%',width:'100%'}} visible={true} transparent={true}>
          <RemovePhoneModal
          title="Log Out"
          continueLabel='Log Out'
          message="Are you sure you want to log out?"
          handleRemove={confirmLogout}
          setRemoveModal={setLogOutModal}
          />
        </Modal>
      }
        <FeedbackWidget onClose={() => setFeedback(false)} visible={feedback}/>
    
      </>
  );
}

const DrawerLabel = props => {
  const {isWebCompact} = useResponsiveLayout();

  return (
    <View style={{
      ...props.style,
    }
    }>
      <View style={{ 
        flexDirection: 'row', 
        alignItems: 'center',
        paddingVertical: !isWebCompact ? 0 : 10,
        }}>
        <Text
          style={{
            fontSize: 16,
            color: props.focused ? 'white' : 'black',
            marginLeft: -20,
            fontFamily: AppFonts.Roboto_Regular,
          }}>
          {!isWebCompact && props.label}
        </Text>
        {!!props.showIndicator && props.showIndicator > 0 && (
          <View style={{marginLeft:'1em',backgroundColor:'#FEC781',borderRadius:100, justifyContent:'center',alignItems:'center', height:25,width:25}}><Text style={{fontSize:12,lineHeight:25,fontFamily:AppFonts.Roboto_Regular,color:'black'}}>{props.showIndicator}</Text></View>
        )}
      </View>
    </View>
  )
}

const DrawerIcon = props => {
  return (
    <Icon
      name={props.iconName}
      type={props.iconType}
      size={20}
      color={props.focused ? 'white' : 'black'}
    />
  )
}


const styles = StyleSheet.create({
  expandableMenuItem: {
    paddingLeft: '52%',
    paddingVertical: 12, 
    backgroundColor: 'white', 
    width: '260%', 
    marginLeft: '-72%'
  }
})