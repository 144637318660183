import React, { useEffect, useState, useMemo } from 'react';
import { Text, View, Switch, Modal, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from 'native-base';
import CustomButton from '../../../Components/Button';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import End_Points from '../../../Constants/Api';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';
import storeData from '../../../AsyncUtils/StoreData';
import Alert from '../../../Components/Alert';
import Loader from '../../../Components/Loader';
import { setAccountInactivityThunk } from '../../../Redux/account_active';
import { loadRecentMessagesThunk } from '../../../Redux/recent_message';

const text1 =
  'If you are using a non-public computer and want to remain logged in all day, you can disable the inactivity timer.';
const text3 =
  'Red and to the left is disabled, green and to the right is enabled.';

const text4 =
  'Your group administrator has disabled your ability to disable the inactivity timer. Please contact your group administrator (not MyRepChat customer support) if you have any questions.';

const AppOptions = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [isEnabled, setEnabled] = useState(false);

  const [visible, setVisible] = useState(false);
  const [inactiveTimeout, setInactiveTimeout] = useState(10);
  const { profile } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const Toast = useToast();
  const [enterToSend, setEnterToSend] = React.useState(false);

  useEffect(() => {
    if (profile?.group?.active && !profile?.group?.allowInactivityDisable) {
      setVisible(true);
    } else {
      dispatch(setAccountInactivityThunk(isEnabled));
    }
  }, [isEnabled]);

  useEffect(() => {
    const focus = navigation.addListener('focus', async () => {
      checkIfEnterToSendEnabled();
    })

    return focus;
  }, [navigation])

  const checkIfEnterToSendEnabled = async () => {
    const result = await getData('@entertosend');
    setEnterToSend(result);
  }

  const handleCheckEnterToSend = async () => {
    await storeData('@entertosend', !enterToSend);
    setEnterToSend(!enterToSend);
  }


  useEffect(() => {
    setEnabled(profile?.user?.inactivityTimerEnabled);
    const timeout =
      profile?.group?.inactivityTimeout ||
      profile?.affiliate?.inactivityTimeout;
    if (timeout) {
      setInactiveTimeout(timeout);
    }
  }, [profile]);

  const text = useMemo(() => {
    return `With the inactivity timer turned on (toggle to the right), you will be automatically logged out after ${inactiveTimeout} minutes of no activity.`;
  }, [inactiveTimeout]);

  const handleModalClose = () => {
    setVisible(false);
    setEnabled(true);
  };

  const handleSubmit = () => {
    setVisible(false);
    setEnabled(true);
  };
  const handleUpdateTimer = async (toggle) => {
    setLoading(true);
    try {
      const body = {
        action: 'update_inactivity_timer',
        inactivityTimerEnabled: toggle,
      };

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Updated Inactivity Timer.',
          status: 'success',
        });
        navigation.pop();
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const clearAllNotifications = () => {
    Alert.alert(
      "Clear Notifications",
      "Are you sure you'd like to clear all notifications for your account?",
      [
        {
          text: "Cancel",
          onPress: () => {return;},
          style: "cancel",
        },
        {
          text: 'Confirm',
          onPress: async () => {
            setLoading(true);
            const body = {
              action: "delete_notifications" 
            };
            
            let device = await getData('@device_id');
            const headers = { 'x-device': device };
            let Config = {
              method: 'post',
              url: End_Points.Base_url + End_Points.End,
              data: body,
              headers: headers,
              redirect: 'follow',
            };
            let result = await RequestMaker(Config);
            if(result.data.success){
              dispatch(loadRecentMessagesThunk());
              Toast.show({title:'Successfully cleared notifications', status:'success'})
            }
            setLoading(false);
          },
          style:"confirm"
        }
      ],
    )    
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.container}>
        <View
          style={{
            marginBottom: 20,
            marginTop: 20,
          }}>
          <Text style={styles.text}>{text1}</Text>
          <View style={{ flexDirection: 'row', marginVertical: 20 }}>
            <Text style={[styles.text, { marginRight: 15 }]}>
              Inactivity Timer
            </Text>
            <Switch
              disabled={!profile?.group?.allowInactivityDisable}
              value={isEnabled}
              onValueChange={(v) => {
                setEnabled(v);
                handleUpdateTimer(v);
              }}
              trackColor={{ false: 'red', true: 'green' }}
            />
            <Text style={{color:isEnabled ? 'green' : 'red',marginLeft:10}}>{isEnabled ? 'ON' : 'OFF'}</Text>
          </View> 
          <Text style={styles.text}>{text}</Text>
          <Text style={styles.text}>{text3}</Text>
        </View>
        <CustomButton onPress={clearAllNotifications} title="Clear All Notifications"  style={{marginTop:25, marginBottom: 25}}/>
          <Text>Use the switch below to enable or disable pressing the 'Enter' key to send messages</Text>
          <View style={{ flexDirection: 'row', marginVertical: 20 }}>
            <Text style={[styles.text, { marginRight: 15 }]}>
              Enter to send
            </Text>
            <Switch
              value={enterToSend}
              onValueChange={handleCheckEnterToSend}
              trackColor={{ false: 'red', true: 'green' }}
            />
            <Text style={{color:enterToSend ? 'green' : 'red',marginLeft:10}}>{enterToSend ? 'ON' : 'OFF'}</Text>
          </View> 
      </View>
      {loading && <Loader />}
      {visible && (
        <ContactAdminModal
          visible={visible}
          setVisible={setVisible}
          handleModalClose={handleModalClose}
          handleSubmit={handleSubmit}
        />
      )}
    </SafeAreaView>
  );
};
export default AppOptions;

const ContactAdminModal = (props) => {
  return (
    <Modal
      animationIn={'fadeIn'}
      useNativeDriver={true}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      visible={props.visible}
      transparent={true}
      onDismiss={() => {
        props.setVisible(false);
      }}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}
      onBackdropPress={() => props.setVisible(false)}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#0007',
        }}>
        <View
          style={{
            backgroundColor: 'white',
            padding: 10,
            width: '90%',
            maxWidth: 400,
          }}>
          <Text style={{ textAlign: 'center', fontSize: 18 }}>Message</Text>
          <View
            style={{
              height: 1,
              backgroundColor: AppColor.PrimaryColor,
              marginVertical: 10,
            }}></View>
          <Text style={{ color: AppColor.Dark }}>{text4}</Text>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 10,
            }}>
            <CustomButton
              title={'OK'}
              style={{ width: '40%' }}
              onPress={() => props.setVisible(false)}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '90%',
    alignSelf: 'center',
  },
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    color: '#000000',
    lineHeight: '1.2em',
    fontSize: 14,
  },
});
