import * as React from 'react';
import {
  Text,
  View
} from 'react-native';
import { useToast, Menu } from 'native-base';
import AppColor from '../Constants/Color';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';
import getData from '../AsyncUtils/GetData';
import { setToken, setProfile } from '../Redux';
import { loadRecentMessagesThunk } from '../Redux/recent_message';
import {
  loadAllContactsThunk,
  loadAllBlockedContactsThunk
} from '../Redux/contacts';
import { loadRecentMediaThunk } from '../Redux/recent_media';
import { loadSavedMessagesThunk } from '../Redux/saved_messages';
import { loadAccountStatThunk } from '../Redux/account_stat';
import { loadAllAutoResponseThunk } from '../Redux/auto_response';
import { loadAccountHealthThunk } from '../Redux/account_health';
import {
  loadIntegrationProviderThuunk,
  loadIntegrationsThuunk
} from '../Redux/integrations';
import {
  loadGroupAddOnsThuunk,
  loadBillingDetailsThuunk
} from '../Redux/add_ons';
import { loadAllDistributionsThunk } from '../Redux/distributions';
import { Icon } from 'react-native-elements';

const SwitchAccountModal = ({
  visible, setVisible, setLoading, dispatch, tenant, user, onSwitchAccount
}) => {
  const Toast = useToast();
  const [activeAccount, setActiveAccount] = React.useState(null);

  React.useEffect(() => {
    if (user) {
      let accounts = user?.accounts ?? [];
      for (let x of accounts) {
        if (x.id == tenant?.id) {
          setActiveAccount(x);
        }
      }
    }
  }, [user, tenant]);

  // dashboard thunk com over here
  const loadDashBoardData = () => {
    dispatch(loadAccountStatThunk());
  };
  const loadRecentMessages = async () => {
    dispatch(loadRecentMessagesThunk());
  };
  const LoadAgain = (tenant) => {
    dispatch(loadAccountHealthThunk(tenant));
    loadDashBoardData();
    loadRecentMessages();
    dispatch(loadAllContactsThunk());
    dispatch(loadRecentMediaThunk());
    dispatch(loadSavedMessagesThunk());
    dispatch(loadIntegrationProviderThuunk());
    dispatch(loadIntegrationsThuunk());
    dispatch(loadGroupAddOnsThuunk());
    dispatch(loadBillingDetailsThuunk());
    dispatch(loadAllDistributionsThunk());
    dispatch(loadAllAutoResponseThunk());
    dispatch(loadAllBlockedContactsThunk());
  };

  const handleSubmit = async (selectedAccount) => {


    try {
      setLoading(true);
      setVisible(false);
      const fcmToken = await getData('fcmToken');
      const body = {
        action: 'authorize',
        account: selectedAccount.id,
        newToken: true,
        pushKey: fcmToken,
        expiration: 1000 * 60 * 60 * 24 * 7,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let result = await RequestMaker(Config);
      if (result.status == 200) {
        if (result.headers && result.headers['x-dart-token']) {
          dispatch(
            setToken({
              authenticated: true,
            })
          );
        }
        if (result.data) {
          dispatch(setProfile(result.data));
        }

        if(onSwitchAccount){
          onSwitchAccount();
        }

        LoadAgain(selectedAccount.id);
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }



  }; 
      return(
        <>
        {
          user?.accounts && 
          user.accounts.map((x, i) => {
  
            let selected = activeAccount?.id == x.id;
            return (
              <Menu.Item
              style={{cursor:'pointer'}}
              onPress={() => {
                handleSubmit(x);
                setActiveAccount(x);
              }}
              >
              <View
                style={{
                  padding: 0,
                  flexDirection:'row',
                  alignItems:'center'
                }}
                >
                <Icon color={selected ? AppColor.PrimaryColor : AppColor.Dark} type='font-awesome' name='user' />
                <Text
                  style={{
                    marginLeft:20,
                    color: selected ? AppColor.PrimaryColor : AppColor.Dark,
                  }}>
                  {x.contact}
                </Text>
              </View>
              </Menu.Item>
  
            );
          })
        }
        </>
      )
      
  ;
};

export default SwitchAccountModal;