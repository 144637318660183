import React from 'react'
import { StyleSheet, View } from 'react-native';

const SideBar = props => {

    return(
        <View style={[styles.sidebarContainer, props.style, ]}>
            {props.children}
        </View>
    )
}

const styles = StyleSheet.create({
    sidebarContainer:{
        flex:1,
        height:'100%',
        backgroundColor:'#F9F9F9',
        borderRightWidth:1,
        borderRightColor:'#DEDCD9'
    }
})

export default SideBar;