import React from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Calendar } from 'react-native-calendars';
import AppFonts from "../../Constants/Fonts";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import getQuery from "../getQuery";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader/index.web";
import ComponentAccordion from "../../Components/ComponentAccordion";
import moment from "moment";
import { Tooltip } from "native-base";
import { Icon } from "react-native-elements";
import AppColor from "../../Constants/Color";

const ArchiveAudit = ({tenant}) => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
    const yearAndMonth = `${year}-${month}`;
    const [day, setDay] = React.useState(null);
    const [currentDate, setCurrentDate] = React.useState(yearAndMonth);

    const queryObject = {
        action: 'get_archiveaudit_by_day',
        month: yearAndMonth,      
    }
    if(tenant){
        queryObject.tenantId = tenant.data.tenant.id;
    } else {
        queryObject.affiliateId = affiliate.id;
    }

    const { refetch, data, isLoading, isError } = getQuery(queryObject);

    if (isLoading) return <Loader backgroundColor={'transparent'} />

    if (!data) return null;

    const markedDates = Object.entries(data.audits).reduce((acc, [key, value]) => {
        let dotColor;
        switch (value.summary.worstState) {
            case 'none':
                dotColor = 'black';
                break;
            case 'failed':
                dotColor = 'red';
                break;
            case 'completed':
                dotColor = 'green';
                break;
            default:
                dotColor = 'black'; // Default color if none of the cases match
        }
        if (key === day) {
            acc[key] = { selected: true, marked: true, dotColor: dotColor };
            return acc;
        }

        acc[key] = { marked: true, dotColor: dotColor };
        return acc;
    }, {});

    const getDateFormat = timestamp => moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');

    const RenderComponent = ({item}) => {
        console.log(item);
        return(
            <>
            <Text style={{fontFamily:AppFonts.Roboto_Bold}}>
                Provider
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {item.provider}
            </Text>    
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                State
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {item.state}
            </Text>    
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Date/Time
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {getDateFormat(item.timestamp)}
            </Text>    
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Range Start
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {getDateFormat(item.rangeStart)}
            </Text>    
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Range End
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {getDateFormat(item.rangeEnd)}
            </Text>    
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Message Total
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {item.totalMessages}
            </Text>    
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Retry Count
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {item.retryCount}
            </Text>    
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Last Result Description
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {item.lastResultDescription}
            </Text>    
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Tenant Email
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {item.tenantEmail}
            </Text>    

            </>
        )
        
    }

    return (
        <ScrollView contentContainerStyle={{flex:1}}>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 20 }}>
                Select the date you would like to view an archive audit for. The details for the audit will be displayed below the calendar.
            </Text>

            <Calendar
                disableMonthChange={true}
                current={currentDate}
                markedDates={markedDates}
                style={{marginBottom:20}}
                dayComponent={({date, state}) => {
                    const dayData = data?.audits[date.dateString];
                    const icon = dayData?.summary?.worstState === 'completed' ? 'checkmark-outline' : dayData?.summary?.worstState === 'failed' ? 'alert' : dayData?.summary?.worstState === 'inprogress' ? 'timer-outline' : null;
                    const iconColor = dayData?.summary?.worstState === 'completed' ? 'green' : dayData?.summary?.worstState === 'failed' ? 'red' : dayData?.summary?.worstState === 'inprogress' ? 'orange' : null;
                    return (
                        <Tooltip
                        placement="top"
                        hasArrow={true}
                         label={dayData?.summary?.worstState}>
                            <View>
                      <TouchableOpacity onPress={() => setDay(date.dateString)} >
                        <Text style={{ color: day === date.dateString ? 'white' : 'black', backgroundColor: day === date.dateString ? AppColor.PrimaryColor : 'transparent',borderRadius:100, textAlign: 'center',fontFamily:AppFonts.Roboto_Regular,fontSize:16}}>{date.day}</Text>
                       { icon ? 
                       <Icon name={ icon } color={iconColor} type='ionicon'/>
                        :
                        <View style={{height:16}}/>
                    }

                      </TouchableOpacity>
                      </View>
                      </Tooltip>

                    );
                }}
                onMonthChange={e=> {
                    const date = `${e.year}-${e.month}`;
                    const body = {
                        action: 'get_archiveaudit_by_day',
                        month: date,      
                    }
                    if(tenant){
                        body.tenantId = tenant.data.tenant.id;
                    } else {
                        body.affiliateId = affiliate.id;
                    }
                    refetch(body);
                    setCurrentDate(date);
                }}
                renderArrow={(direction) =>
                    direction == 'left' ? (
                        <MaterialIcons
                            name="chevron-left"
                            size={22}
                        />
                    ) : (
                        <MaterialIcons
                            name="chevron-right"
                            size={22}
                        />
                    )
                }
            />

            {
                data.audits[day]?.summary && 
                <View style={{flexDirection:'row',justifyContent:'space-evenly',marginBottom:10,borderBottomWidth:1,borderBottomColor:'rgba(0,0,0,.2)',paddingBottom:5}}>
                    <Text style={{fontFamily:AppFonts.Roboto_Bold}}>{data.audits[day].summary.messageCount} Messages</Text>
                    <Text style={{fontFamily:AppFonts.Roboto_Bold}}>{data.audits[day].summary.archiveDetailCount} Archives</Text>
                    <Text style={{fontFamily:AppFonts.Roboto_Bold}}>{data.audits[day].summary.archiveCount} Audits</Text>
                    <Text style={{fontFamily:AppFonts.Roboto_Bold}}>{data.audits[day].summary.failureArchiveCount} Failures</Text>

                </View>
            }

            <ComponentAccordion
            data={data.audits[day]?.details}
            renderComponent={item => <RenderComponent item={item}/>}
            renderListItem={item => [moment(item.timestamp).format('MMMM Do YYYY, h:mm:ss a'),item.tenant,item.state]}
            columns={['Time','Account','Status']}
            />
            
            


        </ScrollView>
    )
}

export default ArchiveAudit;
