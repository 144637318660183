import React from "react";
import { View, StyleSheet } from "react-native";
import { Icon } from "react-native-elements";
import AppFonts from "../Constants/Fonts";

const SimpleDropdown = ({ data, value, onChange, style}) => {
  const defaultValue = data.find(e => e.value === value)?.value || '';

  return (
    <View style={[styles.container, {...style}]}>
      <select
        value={defaultValue}
        onChange={(event) => onChange(event.target.value)}
        style={{    flex: 1,
            fontFamily: AppFonts.Roboto_Regular,
            border: 'none',
            outline: 'none',
            appearance: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',}}
      >
        {data.map(e => (
          <option key={e.value} value={e.value}>
            {e.label}
          </option>
        ))}
      </select>
      <Icon name='angle-down' type='font-awesome' style={styles.icon} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 450,
    borderRadius: 5,
    border: '1px solid black',
    padding: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  select: {

    // Add web-specific styles here if needed
  },
  icon: {
    marginLeft: 5,
  },
});

export default SimpleDropdown;
