/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Text, TouchableOpacity, View, Image, Alert, Linking, ScrollView } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import AlphabetList from 'react-native-flatlist-alphabet';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Tooltip, useToast } from 'native-base';
import { Icon } from 'react-native-elements';
import Modal from 'modal-react-native-web';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { providers } from '../IntegrationProvider';
import End_Points from '../../Constants/Api';
import { loadMemberNumbers, callContact, deleteItem } from './helpers';
import { RenderPhoneModal, RemovePhoneModal } from './modals';
import Loader from '../../Components/Loader';
import { loadAllBlockedContactsThunk } from '../../Redux/contacts';

const Contacts = ({ navigation }) => {
  const contactData = useSelector((state) => state.contacts).blocked_contacts;
  const [search, setSearch] = React.useState('');
  const [allContactData, setAllContactData] = React.useState([]);
  const [phoneModalVisible, setPhoneModalVisible] = React.useState(false);
  const [phoneNumbers, setPhoneNumbers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [selectedNumber, setSelectedNumber] = React.useState('');
  const dispatch = useDispatch();
  const Toast = useToast();
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const [hasVoiceAddon, setHasVoiceAddon] = React.useState();

  React.useEffect(() => {
    if (tenant?.voice) {
      setHasVoiceAddon(tenant.voice);
    }
  }, [tenant]);


  React.useEffect(() => {
    if (search.trim().toLowerCase() !== '') {
      const searchedData = contactData.filter(
        (x) =>
          (`${x.fname.toLowerCase()} ${x.lname.toLowerCase()}`).includes(search.trim().toLowerCase())
        );
      setAllContactData(searchedData);
    } else {
      setAllContactData(contactData);
    }
  }, [search, contactData]);


  const handleCall = async (phoneNumber) => {
    callContact(phoneNumber)
    .then(res => {
      if(res?.data?.success){
        Toast.show({
          duration: 2000,
          title: 'Call initiated, Use your phone to complete the call',
          status: 'success',
        });
        setTimeout(() => {
          Linking.openURL(`tel:${phoneNumber}`);
        }, 2000);
      } else {
        Alert.alert('Fail to Send Message', res?.data?.failures?.join(','));
      }
    })    
  };

  const handleLoadMemberNumbers = async (id) => {
    setIsLoading(true);
    loadMemberNumbers(id)
    .then(res => {
      if(res?.data?.success) setPhoneNumbers(res?.data?.phonenumbers)
      setIsLoading(false);
    })    
  };

  const handleRemove = async () => {
    setRemoveModal(false);
    setIsLoading(true);
    deleteItem(selectedNumber)
    .then( res => {
      if (res?.data?.success) {
        Toast.show({ duration: 2000, title: res?.data?.message || 'Successfully Deleted!', status: 'success' });
        dispatch(loadAllBlockedContactsThunk());
      } else {
        Toast.show({
          duration: 2000,
          title: res?.data?.errorMessage || 'Something went wrong',
          status: 'error',
        });
      }
      setIsLoading(false);
    })
  };

  const renderListView = ({ item }) => {
    let image = require('../../Assets/Images/splashlogo.png');
    let provider = Object.values(providers).find((x) => x.name == item.source);
    if (provider) {
      image = provider.smallImageUrlPath;
    }
    return (
      <TouchableOpacity
      accessible={true}
      accessibilityRole='button'
        onPress={() => {
          navigation.navigate('ContactEdit', { item: item });
        }}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          marginBottom: 5,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <img src={image} style={{ height: 40, width: 40 }} />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.name}
          </Text>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
        { hasVoiceAddon && 
        <Tooltip
        label="Call"
        openDelay={500}
        placement="top"
        hasArrow={true}>
          <View
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon
              accessibilityRole='button'
              onPress={() => {
                setPhoneModalVisible(true);
                handleLoadMemberNumbers(item.id);
              }}
              name={'call-outline'}
              type={'ionicon'}
              style={{ fontSize: 27, color: AppColor.Dark }}
            />
          </View>
        </Tooltip>
          }
        <Tooltip
          label="Message"
          openDelay={500}
          placement="top"
          hasArrow={true}>
          <View
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon
            accessibilityRole='button'

              onPress={() =>
                navigation.navigate('Chats', { memberData: item, dl: null })
              }
              style={{ fontSize: 27, color: AppColor.Dark }}
              name={'message1'}
              type={'antdesign'}
            />
          </View>
        </Tooltip>
          <Tooltip
          label="Delete"
          openDelay={500}
          placement="top"
          hasArrow={true}>
          <View
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <TouchableOpacity
              accessibilityRole='button'

              onPress={() => {
                setRemoveModal(true);
                setSelectedNumber(item.id);
              }}>
              <Icon name="trash-outline" type='ionicon' size={30} />
            </TouchableOpacity>
          </View>
        </Tooltip>

        </View>
      </TouchableOpacity>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Blocked Contacts'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <Search onChangeText={(t) => setSearch(t)} placeholder="Filter by name" />
      <ScrollView style={{ flex: 1, paddingVertical: 15, paddingHorizontal: 30 }}>

        <AlphabetList
          data={allContactData.map((x, i) => ({
            value: `${x.name ?? ''}`,
            item: x,
            key: x.id + '' + i,
          }))}
          renderItem={renderListView}
          renderSectionHeader={(d) => {
            return (
              <View style={{ marginHorizontal: 10, marginVertical: 5 }}>
                <Text
                  style={{
                    backgroundColor: AppColor.PrimaryColor,
                    width: 20,
                    textAlign: 'center',
                    color: 'white',
                  }}>
                  {d.title}
                </Text>
              </View>
            );
          }}
          sectionHeaderHeight={40}
          indexLetterColor={AppColor.PrimaryColor}
          letterItemStyle={{ marginTop: 8 }}
        />
      </ScrollView>

      <Modal visible={phoneModalVisible} transparent={true}>
          <RenderPhoneModal
            handleCall={handleCall}
            phoneNumbers={phoneNumbers}
            setPhoneModalVisible={setPhoneModalVisible}
          />
      </Modal>      
      <Modal visible={removeModal} transparent={true}>
          <RemovePhoneModal
            handleRemove={handleRemove}
            setRemoveModal={setRemoveModal}
          />
        </Modal>


    
      {isLoading && <Loader />}
    </SafeAreaView>
  );
};
export default Contacts;
