import React from 'react';
import {
  Text,
  View,
  TouchableOpacity, Linking, Modal
} from 'react-native';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import CustomButton from '../../Components/Button';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import RequestMaker from '../../Middleware/ApiCaller';
import { useDispatch, useSelector } from 'react-redux';
import { clearProfile } from '../../Redux';
import { DrawerActions, useNavigation } from '@react-navigation/native';

export function TermsOfUseModal({visible, setVisible, setLoader}) {
  const { tous } = useSelector((state) => state.User.profile);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const handleAgree = async () => {
    setVisible(false);

    try {
      setLoader(true);
      tous.forEach(async e => {
        if (e.id) {
          const body = {
            action: 'accept_tou',
            tou: e.id,
          };
          await RequestMaker(body);
        }
      })
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const handleDisagree = async () => {
    const body = {
      action: 'logout',
    };
    let result = await RequestMaker(body);
    if (result.status == 200) {
      dispatch(clearProfile());
      navigation.dispatch(DrawerActions.closeDrawer());
      navigation.navigate('Login');
    }
  }

  if(!visible) return null;
  return <Modal
    animationIn={'fadeIn'}
    useNativeDriver={true}
    animationOut={'fadeOut'}
    visible={visible}
    transparent={true}
    ariaHideApp={false}
    style={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      border: 'none',
    }}>

    <View
      style={{
        flex: 1,
        backgroundColor: '#0007',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <View
        style={{
          backgroundColor: AppColor.SecondaryColor,
          width: '95%',
          maxWidth: 420,
        }}>
        <View
          style={{
            backgroundColor: AppColor.PrimaryColor,
            padding: 10,
          }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Bold,
              fontSize: 18,
              color: AppColor.SecondaryColor,
            }}>
            Terms of Use
          </Text>
        </View>
        <View style={{ padding: 15,alignItems:'flex-start' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 16,
              marginBottom: 15,
            }}>
            In order to use MyRepChat you must agree to the following Terms
            Of Use. (click link to open in new window)
          </Text>
          {tous?.length > 0 &&
            tous.map((v) => (
              <TouchableOpacity
                onPress={() => {
                  Linking.canOpenURL(v.text)
                    .then(result => {
                      if (result) {
                        window.open(v.text, '_blank');
                      }
                    });
                }}
                key={v.id}
                style={{
                  flexDirection: 'row',
                  alignItems:'center',
                  marginVertical:10,
                  justifyContent: 'center',
                }}>
                <EvilIcons name="external-link" color='blue' size={30} />
                <Text
                  style={{
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 16,
                    marginLeft: 15,
                    color:'blue'
                  }}>
                  Terms of Use
                </Text>
              </TouchableOpacity>
            ))}
          <View
            style={{
              flexDirection: 'row',
              marginTop: 15,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <CustomButton
              title="I agree"
              onPress={handleAgree}
              width={120} />
            <TouchableOpacity
              onPress={handleDisagree}
              style={{ marginLeft: 15 }}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  color: AppColor.PrimaryColor,
                  textDecorationLine: 'underline',
                  textDecorationColor: AppColor.PrimaryColor,
                  fontSize: 16,
                  textTransform: 'uppercase',
                }}>
                I do not agree
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  </Modal>;
}
