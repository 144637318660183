import Alert from 'Alert';
import { Menu, useToast } from 'native-base';
import React from 'react';
import { Modal, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { useSelector } from 'react-redux';
import ListHeader from '../Components/AlphabetList/ListHeader';
import CustomButton from '../Components/Button/index.web';
import CustomInput from '../Components/CustomInput/index.web';
import { Content, Wrapper } from '../Components/Layout';
import ModalWrapper from '../Components/Modals/ModalWrapper';
import Search from '../Components/Search';
import SimpleDropdown from '../Components/SimpleDropdown';
import AppColor from '../Constants/Color';
import AppFonts from '../Constants/Fonts';
import RequestMaker from '../Middleware/ApiCaller';
import { formatNumber } from '../Middleware/helpers';
import CSVImportModal from '../Screens/Contacts/CSVImport';
import NewAlphabetList from '../Screens/Contacts/NewAlphabetList';
import getQuery from './getQuery';

const Accounts = ({ route, navigation }) => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    let body = {
        action: "get_tenants",
        limit: 100,
        offset: 0,
        group: affiliate.id,
    }
    const { data, isLoading, refetch } = getQuery(body, {skip: route?.params?.tenantStatus});
    const Toast = useToast();
    const [search, setSearch] = React.useState('');
    const [createAccountModal, setCreateAccountModal] = React.useState(false);
    const Countries = getQuery({ action: 'get_countries' });
    const Timezones = getQuery({ action: 'get_timezones' });
    const countryData = Countries.data?.countries
        ? Countries.data.countries.map(e => ({ label: e.label, value: e.isoCode }))
        : [];

    const timezoneData = Timezones.data?.timezones
        ? Timezones.data.timezones.map(e => ({ label: e.label, value: e.id }))
        : [];

    const defaultNewAccountState = {
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        contact: '',
        email: '',
        isoCountry: '',
        timezone: '',
        status: 1,
        crd: '',
    }
    const [newAccountData, setNewAccountData] = React.useState(defaultNewAccountState)
    const [isCSVVisible,setIsCSVVisible] = React.useState(false);
    const value_id = {
        1: 'active',
        2: 'inactive',
        3: 'review',
        4: 'trial',
        5: 'default',
        90: 'comp',
    }
    
    const [filter, setFilter] = React.useState('all');
    
    React.useEffect(()=>{
        if(route?.params?.tenantStatus){
            setFilter(value_id[route.params.tenantStatus]);
            console.log({
                ...body,
                tenantStatus: route?.params?.tenantStatus
            });
            
            refetch({
                ...body,
                tenantStatus: route?.params?.tenantStatus
            });
        }
    },[route?.params?.tenantStatus])

    const [page, setPage] = React.useState(1);

    if (!data) return null;

    const renderContactList = ({ item }) => {
        const handleClick = () => {
            if(item.status === 6 || item.status === 7){
                Alert.alert('Error', 'This account is not active yet. Please review it first.');
                return;
            }
        
            navigation.navigate('AccountDetails', { tenant: item.id })
        }
        const STATUS_TEXT = {
            1: "Pending",
            2: "Approved",
            3: "Rejected",
            4: "Retired",
            90: "Deleted",
        }
        const ACCOUNT_STATUS = {
            1: "Active",
            2: "Inactive",
            3: "Review",
            4: "Trial",
            5: "Default",
            6: "Initial",
            7: 'Initial',
            9: "Deleted",
            90: "Comp",
        }

        return (
            <TouchableOpacity onPress={handleClick} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ width: '20%', flexDirection: 'row', alignItems: 'center' }}>
                    <Icon name='user' type='font-awesome' size={28} style={{ marginLeft: 12, marginRight: 12 }} />
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{item.contact}</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'center', fontFamily: AppFonts.Roboto_Regular, backgroundColor: AppColor.PrimaryColor, paddingVertical: 2, paddingHorizontal: 5, borderRadius: 5, width: 100, color: 'white' }}>{ACCOUNT_STATUS[item.status] || 'Unknown'}</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{STATUS_TEXT[item.vcardStatus] || 'No Vcards'}</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{formatNumber(item.tw_number)}</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{item.email}</Text>
                </View>
                

            </TouchableOpacity>
        )
    }

    const ACCOUNT_STATES = [
        {
            value: 1,
            label: 'Active'
        },
        {
            value: 2,
            label: 'Inactive'
        },
        {
            value: 3,
            label: 'Review'
        },
        {
            value: 4,
            label: 'Trial'
        },
        {
            value: 5,
            label: 'Default'
        },
        {
            value: 9,
            label: 'Deleted'
        },
        {
            value: 90,
            label: 'Comp'
        }
    ]

    const handleCreateAccount = async () => {
        const body = {
            action: 'create_tenant',
            features: [8],
            phone: '',
            affiliate: affiliate.id,
            ...newAccountData,
        }
        try {
            const result = await RequestMaker(body);
            if (result.data.success) {
                Toast.show({
                    title: 'Account created successfully',
                    status: 'success'
                })
                setNewAccountData(defaultNewAccountState);
                refetch();
                setCreateAccountModal(false);
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: 'error'
                })
            }
        } catch (err) {
            Toast.show({
                title: err.message,
                status: 'error'
            })
        }
    }

    const handleImportMembers = async (e) => {
        try{
        const body = { 
                map: e.map,
                objects: e.objects,
                group: affiliate.id,
                action: "import_tenants"     
        }
        const result = await RequestMaker(body);
        if(new Object(result.data).hasOwnProperty('imported')){
            const numImported = result.data.imported;
            Toast.show({
                title: `${numImported} account(s) imported successfully`,
                status: 'success'
            })
            setIsCSVVisible(false);
            refetch();
        } else if(result.data.errorMessage){
            Toast.show({
                title: result.data.errorMessage,
                status: 'error'
            })
        }
    } catch(e){
        Toast.show({
            title: 'An error occurred while importing accounts',
            status: 'error'
        })
    }
    }

    const handleSearch = () => {
        refetch(
            {
                action: 'get_tenants',
                limit: 100,
                offset: 0,
                group: affiliate.id,
                google: search
            }
        )
    }

    const clearSearch = () => {
        refetch()
    }


    const handleFilterAccounts = val => {
        setFilter(val);

        const body = {
            action: 'get_tenants',
            limit: 100,
            offset: 0,
            group: affiliate.id,
        }

        switch(val){
            case 'active': {
                body.tenantStatus = 1;
                break;
            }
            case 'inactive': {
                body.tenantStatus = 2;
                break;
            }
            case 'review': {
                body.tenantStatus = 3;
                break;
            }
            case 'trial': {
                body.tenantStatus = 4;
                break;
            }
            case 'default': {
                body.tenantStatus = 5;
                break;
            }
            case 'comp': {
                body.tenantStatus = 90;
                break;
            }
            case 'pending': {
                body.vcardStatus = 1;
                break;
            }
            case 'approved': {
                body.vcardStatus = 2;
                break;
            }
            case 'rejected': {
                body.vcardStatus = 3;
                break;
            }
            case 'retired': {
                body.vcardStatus = 4;
                break;
            }
        }

        refetch(body)
    }

    return (
        <Wrapper>
            <Content>
                <View style={{ width: '100%' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Search onClear={clearSearch} onPressSearch={handleSearch} onSubmitEditing={handleSearch} containerStyle={{ flex: 1 }} onChangeText={val => setSearch(val)} />
                        <Menu style={[{ maxHeight: 300, overflowY: 'scroll' }]} trigger={triggerProps => {
                            return (
                                <TouchableOpacity style={{ width: '15%' }} {...triggerProps}>
                                    <CustomButton {...triggerProps} icon={
                                        <Icon name='add' type='MaterialIcon' size={18} color={'white'} />
                                    } title={'Account'} />
                                </TouchableOpacity>
                            )
                        }}>
                            <Menu.Item style={{ flexDirection: 'row', gap: 10, justifyContent: 'flex-start' }} onPress={() => setCreateAccountModal(true)}>
                                <Icon name='user' type='font-awesome' size={18} color={'black'} />
                                <Text>Create a new account</Text>
                            </Menu.Item>
                            <Menu.Item style={{ flexDirection: 'row', gap: 10, justifyContent: 'flex-start' }} onPress={() => setIsCSVVisible(true)}>
                                <Icon name='file-import' type='font-awesome-5' size={18} color={'black'} />
                                <Text>Import accounts from CSV</Text>
                            </Menu.Item>
                        </Menu>
                    </View>
                    <SimpleDropdown
                    
                    data={[{
                        label: 'All Accounts',
                        value: 'all'
                    },
                    {
                        label: 'Active Accounts',
                        value: 'active'
                    },
                    {
                        label: 'Inactive Accounts',
                        value: 'inactive'
                    },
                    {
                        label: 'Review Accounts',
                        value: 'review'
                    },
                    {
                        label: 'Trial Accounts',
                        value: 'trial'
                    },
                    {
                        label: 'Default Accounts',
                        value: 'default'
                    },
                    {
                        label: 'Comp Accounts',
                        value: 'comp'
                    },
                    {
                        label: 'Pending vCards',
                        value: 'pending'
                    },
                    {
                        label: 'Approved vCards',
                        value: 'approved'
                    },
                    {
                        label: 'Rejected vCards',
                        value: 'rejected'
                    },
                    {
                        label: 'Retired vCards',
                        value: 'retired'
                    }
                    ]}

                    onChange={handleFilterAccounts}
                    value={ filter }
                    style={{marginLeft:10}}
                />

                </View>
                <NewAlphabetList
                    data={data.tenants.filter(e => e?.contact?.toLowerCase().includes(search?.toLowerCase()) || e.tw_number.includes(search) || e?.email?.toLowerCase()?.includes(search?.toLowerCase() ))}
                    sortBy={'name'}
                    RenderItem={renderContactList}
                    listViewProps={{
                        onEndReached: () => {
                            setPage(page + 1);
                            const body = {
                                action: "get_tenants",
                                limit: 100,
                                offset: page * 100,
                                group: affiliate.id,

                            }
                            if(filter){
                                switch(filter){
                                    case 'active': {
                                        body.tenantStatus = 1;
                                        break;
                                    }
                                    case 'inactive': {
                                        body.tenantStatus = 2;
                                        break;
                                    }
                                    case 'review': {
                                        body.tenantStatus = 3;
                                        break;
                                    }
                                    case 'trial': {
                                        body.tenantStatus = 4;
                                        break;
                                    }
                                    case 'default': {
                                        body.tenantStatus = 5;
                                        break;
                                    }
                                    case 'comp': {
                                        body.tenantStatus = 90;
                                        break;
                                    }
                                    case 'pending': {
                                        body.vcardStatus = 1;
                                        break;
                                    }
                                    case 'approved': {
                                        body.vcardStatus = 2;
                                        break;
                                    }
                                    case 'rejected': {
                                        body.vcardStatus = 3;
                                        break;
                                    }
                                    case 'retired': {
                                        body.vcardStatus = 4;
                                        break;
                                    }
                                }
                            }
                            refetch(body, {cache: true})
                        },
                        onEndReachedThreshold: 0.1
                    }}
                    ListHeaderComponent={() => 
                    <ListHeader 
                    sortBy={null} 
                    columns={['First Name', 'Status', 'VCard', 'MRC Number', 'Email']}
                    />}
                />
            </Content>
            {createAccountModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={createAccountModal} transparent={true}>
                    <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                        <ScrollView contentContainerStyle={{ flex: 1 }}>
                            <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Create a new account</Text>
                            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 10 }}>Please provide the basic account information. After the account is created you can access the rest of its settings by choosing it from the account list.</Text>
                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-end', gap: 10 }}>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        value={newAccountData.name}
                                        onChangeText={e => setNewAccountData({ ...newAccountData, name: e })}
                                        title={'Customer/Business Name'} />
                                </View>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        value={newAccountData.address1}
                                        onChangeText={e => setNewAccountData({ ...newAccountData, address1: e })}

                                        title={'Address Line 1'} />
                                </View>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        value={newAccountData.address2}
                                        onChangeText={e => setNewAccountData({ ...newAccountData, address2: e })}
                                        title={'Address Line 2'} />
                                </View>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        onChangeText={e => setNewAccountData({ ...newAccountData, city: e })}
                                        value={newAccountData.city}
                                        title={'City'} />
                                </View>

                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        onChangeText={e => setNewAccountData({ ...newAccountData, state: e })}
                                        value={newAccountData.state}
                                        title={'State/Region'} />
                                </View>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        onChangeText={e => setNewAccountData({ ...newAccountData, zip: e })}
                                        value={newAccountData.zip}
                                        title={'Zip/Postal Code'} />
                                </View>

                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        value={newAccountData.contact}
                                        onChangeText={e => setNewAccountData({ ...newAccountData, contact: e })}
                                        title={'Contact Person'} />
                                </View>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        value={newAccountData.email}
                                        onChangeText={e => setNewAccountData({ ...newAccountData, email: e })}
                                        title={'Contact Person Email'} />
                                </View>
                            </View>
                            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>Country</Text>
                            <SimpleDropdown
                                value={newAccountData.isoCountry}
                                data={countryData}
                                onChange={e => setNewAccountData({ ...newAccountData, isoCountry: e })}
                            />
                            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>Timezone</Text>
                            <SimpleDropdown
                                value={newAccountData.timezone}
                                data={timezoneData}
                                onChange={e => setNewAccountData({ ...newAccountData, timezone: e })}
                            />

                            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>Account State</Text>
                            <SimpleDropdown onChange={e => setNewAccountData({ ...newAccountData, status: e })} value={newAccountData.status} data={ACCOUNT_STATES} />
                            <CustomInput
                                value={newAccountData.cdr}
                                onChangeText={e => setNewAccountData({ ...newAccountData, cdr: e })}
                                title={'CRD Number'}
                                titleStyle={{ marginTop: 10 }}
                                maxLength={16}
                            />
                        </ScrollView>
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 10, marginTop: 20 }}>
                            <CustomButton
                                onPress={() => setCreateAccountModal(false)}
                                title={'Cancel'} outline width={'30%'} />
                            <CustomButton onPress={handleCreateAccount} title={'Save'} width={'30%'} />
                        </View>
                    </ModalWrapper>
                </Modal>
            }
            {
            isCSVVisible && 
            <CSVImportModal
                customFields={
                    [
                        {
                          id: 'name',
                          label: 'Name',
                          defaults: ['all'],
                          hint: '64 max',
                        },
                        {
                          id: 'address1',
                          label: 'Address 1',
                          defaults: ['all'],
                          hint: '64 max',
                        },
                        {
                          id: 'address2',
                          label: 'Address 2',
                          defaults: ['all'],
                          hint: '64 max',
                        },
                        {
                          id: 'city',
                          label: 'City',
                          defaults: ['all'],
                          hint: '64 max',
                        },
                        {
                          id: 'state',
                          label: 'State',
                          defaults: ['all'],
                          hint: '128 max',
                        },
                        {
                          id: 'zip',
                          label: 'Zip Code',
                          defaults: ['all'],
                          hint: '64 max',
                        },
                        {
                          id: 'isoCountry',
                          label: 'Country',
                          defaults: ['all'],
                          hint: '32 max',
                        },
                        {
                          id: 'timezone',
                          label: 'Timezone',
                          defaults: ['all'],
                          hint: '16 max',
                        },
                        {
                          id: 'contact',
                          label: 'Contact',
                          defaults: ['all'],
                          hint: '32 max',
                        },
                        {
                          id: 'phone',
                          label: 'Phone',
                          defaults: ['all'],
                          hint: '24 max',
                        },
                        {
                          id: 'email',
                          label: 'E-Mail',
                          defaults: ['all'],
                          hint: '24 max',
                        },
                        {
                          id: 'mrcNumber',
                          label: 'MyRepChat Number',
                          defaults: ['all'],
                          hint: '128 max',
                        },
                        {
                          id: 'uuid',
                          label: 'Unique ID',
                          defaults: ['complete'],
                          hint: 'uuid',
                        },
                        {
                          id: 'crd',
                          label: 'CRD Number',
                          defaults: ['all'],
                          hint: '16 max',
                        },
                      ]
                }
                visible={isCSVVisible}
                setVisible={setIsCSVVisible}
                handleImportMembers={handleImportMembers}
            />
            }
            
        </Wrapper>
    )
}

export default Accounts;